import Card from 'react-bootstrap/Card';
import {Button, Form} from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import React, {useContext, useEffect, useState} from "react";
import useAxios from "../../utils/useAxios";
import axios, {post} from "axios";
import {Link, useNavigate} from "react-router-dom";
import StatusFilter from "../TaskList/Drop/Drop";
import * as PropTypes from "prop-types";
import "../TaskList/TaskList/TaskList.css"

function Checkbox(props) {
    return null;
}


function GneralSettlements() {
    const {activityChanged} = useContext(AuthContext);
    const {person} = useContext(AuthContext);
    const {task} = useContext(AuthContext);
    const {setCheckWork, checkWork} = useContext(AuthContext);
    const navigate = useNavigate();

    const CehecWork = () => {
        function handleChange(e) {
            setCheckWork(e.target.checked);
            // do whatever you want with isChecked value
        }

        if (activityChanged) {

            // ... your code here
            return (
                <div style={{display: "flex", marginTop: "20px"}}>
                    <Form.Check
                        reverse={false}
                        defaultChecked={checkWork}
                        type="switch" otherProps onChange={e => handleChange(e)}/>
                    <p style={{marginLeft: "10px", marginTop: "4px"}}>Заказы в работе</p>
                </div>
            );
        } else {
            return null
        }

    }
    const Ok = () => {
        if (activityChanged) {
            if (task !== "all") {
                return (
                    <div style={{
                        backgroundColor: "rgb(13,110,252)",
                        width: "120px",
                        borderRadius: "6px",
                        marginBottom: "10px",
                        display: "flex"
                    }}>
                        <StatusFilter/>

                        <div style={{display: "inline-block", marginTop: "5px"}}>✅</div>
                    </div>
                )
            } else {
                return (
                    <div style={{
                        backgroundColor: "rgb(13,110,252)",
                        width: "120px",
                        borderRadius: "6px",
                        marginBottom: "10px",
                        display: "flex"
                    }}>
                        <StatusFilter/>
                        <div style={{display: "inline-block", marginTop: "5px"}}>❎</div>
                    </div>)
            }
        } else {
            return null
        }
    }

    const PostReportCard = () => {
        if (person === "61ade4bc-9464-11e7-aafd-40167eaae633") {
            return (
                <Card
                    bg={'Light'.toLowerCase()}
                    key={'Light'}
                    text={'Light'.toLowerCase() === 'light' ? 'dark' : 'white'}
                    className={"CardReports"}
                    // className="mb-2"
                >
                    <Card.Header><b>Cводный отчет</b></Card.Header>
                    <Card.Body>
                        {/*<Card.Title>{variant} </Card.Title>*/}
                        <Card.Text>
                            Cводный отчет по учету запросов обращений за отчетный период
                        </Card.Text>

                        <Button
                            disabled={activityChanged ? false : true}
                            onClick={() => {
                                navigate(`/report/?param=Post`);
                            }}
                        >
                            <Link
                                style={{textDecoration: "none", color: "#ffffff"}}
                                to={`/report/?param=Post`}
                            >
                                Сформировать отчет
                            </Link>
                        </Button>;
                    </Card.Body>
                </Card>
            )
        } else {
            return null
        }

    }
    return (
        <>
            <div className={"generalBody"}>
                <Card
                    bg={'Light'.toLowerCase()}
                    key={'Light'}
                    text={'Light'.toLowerCase() === 'light' ? 'dark' : 'white'}
                    className={"CardReports"}
                    // className="mb-2"
                >
                    <Card.Header><b>Общие взаиморасчеты</b></Card.Header>
                    <Card.Body>
                        {/*<Card.Title>{variant} </Card.Title>*/}
                        <Card.Text>
                            Детализация начислений и списаний по предоставляемым услугам, текущий остаток средств
                        </Card.Text>

                        <Button
                            disabled={activityChanged ? false : true}
                            onClick={() => {
                                navigate(`/report/?param=General`);
                            }}
                        >
                            <Link
                                style={{textDecoration: "none", color: "#ffffff"}}
                                to={`/report/?param=General`}
                            >
                                Сформировать отчет
                            </Link>
                        </Button>;

                    </Card.Body>
                </Card>
                <Card
                    bg={'Light'.toLowerCase()}
                    key={'Light'}
                    text={'Light'.toLowerCase() === 'light' ? 'dark' : 'white'}
                    className={"CardReports"}
                >
                    <Card.Header><b>Отчет по задачам</b></Card.Header>
                    <Card.Body>

                        {/*<Card.Title>{variant} </Card.Title>*/}
                        <Card.Text>
                            Применяя фильтры по статусам задач, вы можете вывести список задач за указанный период

                        </Card.Text>
                        {/*<div  style={{backgroundColor: "#70839f", width: "120px", borderRadius: "6px", marginBottom: "10px", display: "flex"}}>*/}
                        {/*<StatusFilter/>*/}
                        <Ok/>
                        {/*</div>*/}

                        <Button disabled={activityChanged ? false : true}
                                onClick={() => {
                                    navigate(`/report/?param=Tasks`);
                                }}
                        >
                            <Link
                                style={{textDecoration: "none", color: "#ffffff"}}
                                to={`/report/?param=Tasks`}
                            >
                                Сформировать отчет
                            </Link>
                        </Button>;
                    </Card.Body>
                </Card>
                <Card
                    bg={'Light'.toLowerCase()}
                    key={'Light'}
                    text={'Light'.toLowerCase() === 'light' ? 'dark' : 'white'}
                    className={"CardReports"}
                >
                    <Card.Header><b>ЛУВР лист</b></Card.Header>
                    <Card.Body>

                        {/*<Card.Title>{variant} </Card.Title>*/}
                        <Card.Text>
                            Отчет в форме листа учета рабочего времени по выполненным задачам
                        </Card.Text>

                        <CehecWork/>

                        <Button
                            disabled={activityChanged ? false : true}
                            onClick={() => {
                                navigate(`/report/?param=Louvre`);
                            }}
                        >
                            <Link
                                style={{textDecoration: "none", color: "#ffffff"}}
                                to={`/report/?param=Louvre`}
                            >
                                Сформировать отчет
                            </Link>
                        </Button>
                    </Card.Body>
                </Card>
            </div>
            <PostReportCard/>
        </>
    );
}

export default GneralSettlements;