import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import useAxios from "../../../utils/useAxios";
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
import { v4 as uuidv4 } from 'uuid';
import {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import "./newTask.css"
import Loader from "../../loader/loader";

const NewTask = (props) => {
  const [taskCompleted, setTaskCompleted] = useState(false);
  const taskCompletedClose = () => setTaskCompleted(false);
  const taskCompletedOpen = () => setTaskCompleted(true);
  const [loading, setLoading] = useState(false)


    const {person} = useContext(AuthContext);
    const author = localStorage.getItem("author")
    let authorName = author.split("+").join(" ")
    const {fetchDataTasks} = useContext(AuthContext);
    const inputIdUser = person

    // const [inputUserInn, setInputUserInn] = useState('')
    const [inputPriority, setInputPriority] = useState('Средний')
    const [inputFormulation, setInputFormulation] = useState('')
    const [inputDate, setInputDate] = useState('')
    const [inputDueDate, setInputDueDate] = useState(new Date())

    const [mailTypeRequest , setMailTypeRequest ] = useState('ЗНИ')
    const [mailTypeWork  , setMailTypeWork  ] = useState('Управление производственным предприятием (УПП)')
    const [mailDegreeExposure  , setMailDegreeExposure   ] = useState('Средняя')
    const [mailDegreeValue, setMailDegreeValue] = useState('0')



    const [res, setRes] = useState([]);
    const api = useAxios();
    const fetchData = async () => {
        try {
            const response = await api.get(`/v1/profiluser/${person}/`);
            let data = await response.data
            setRes(data);
        } catch {
            setRes("Something went wrong");
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [person]);

    // setInputUser(res.title)
    const uuid = uuidv4()
    // const [inputUser, setInputUser] = useState(res.title)
    const inputUserInn = res.counterparty_inn
    const inputUser = res.title
    // console.log(inputUser)
    const status = "Постановка задачи"
    const [show, setShow] = useState(false);
    const options = {
        // era: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        // weekday: 'long',
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
    const date = new Date().toLocaleString("ru", options)
    let localDateToday = date.split(",").reverse().join(" ");
    localDateToday = localDateToday.slice(1);
    localDateToday = (localDateToday.split(' ').reverse().join(' '))

    const dueDateTime = inputDueDate.toLocaleString("ru", options)
    let localDate = dueDateTime.split(",").reverse().join(" ");
    localDate = localDate.slice(1);
    localDate = (localDate.split(' ').reverse().join(' '))

    const MailNewTask = () => {
        if (person == "61ade4bc-9464-11e7-aafd-40167eaae633") {
      return(
          <div>
                <Form.Group className={"newTaskElement"} controlId="exampleForm.ControlInput1">
                            <Form.Label>Вид запроса</Form.Label>
                            <select
                                
                                value={mailTypeRequest} onChange={e => setMailTypeRequest(e.target.value)}
                                style={{width: "250px", borderColor: "black"}}
                            >
                                <option value="ЗНИ">ЗНИ</option>
                                <option value="ЗНО">ЗНО</option>
                                <option value="Инцидент">Инцидент</option>
                                <option value="Обновление АС">Обновление АС</option>
                                <option value="ЗНИ 1-2 типа">ЗНИ 1-2 типа</option>
                                <option value="Раздел основного ТЗ">Раздел основного ТЗ</option>

                            </select>

                        </Form.Group>
                                       <Form.Group className={"newTaskElement"} controlId="exampleForm.ControlInput1">
                            <Form.Label>Степень воздействия</Form.Label>
                            <select
                                
                                value={mailDegreeExposure} onChange={e => setMailDegreeExposure(e.target.value)}
                                style={{width: "150px", borderColor: "black"}}
                            >
                                <option value="Критическая">Критическая</option>
                                <option value="Высокая">Высокая</option>
                                <option value="Средняя">Средняя</option>
                                <option value="Низкая">Низкая</option>
                            </select>

                        </Form.Group>
                 <Form.Group className={"newTaskElement"} controlId="exampleForm.ControlInput1">
                            <Form.Label>Вид работ</Form.Label>
                            <select
                                
                                value={mailTypeWork} onChange={e => setMailTypeWork(e.target.value)}
                                style={{width: "250px", borderColor: "black"}}
                            >
                                <option value="Управление торговлей (АСКУ)">Управление торговлей (АСКУ)</option>
                                <option value="Зарплата и управление персоналом (АСЗУП)">Зарплата и управление персоналом (АСЗУП)</option>
                                <option value="Бухгалтерия предприятия (АСБНУ)">Бухгалтерия предприятия (АСБНУ)</option>
                                <option value="Система электронного документооборота (СЭД)">Система электронного документооборота (СЭД)</option>
                                <option value="Управление производственным предприятием (УПП)">Управление производственным предприятием (УПП)</option>
                                <option value="ERP Управление предприятием (УП)">ERP Управление предприятием (УП)</option>
                                <option value="Прочая система на платформе 8.1 и выше">Прочая система на платформе 8.1 и выше</option>
                                <option value="Прочая система на платформе 7.7">Прочая система на платформе 7.7</option>
                                <option value="Управление автотранспортом (УАТ)">Управление автотранспортом (УАТ)</option>

                            </select>

                        </Form.Group>

                        {/*              <Form.Group className="mb-3" style={{width: "200px"}}>*/}
                        {/*    <Form.Label htmlFor="disabledTextInput">Степень воздействия значение</Form.Label>*/}
                        {/*    <Form.Control autoFocus*/}
                        {/*                  placeholder={mailDegreeValue} disabled />*/}
                        {/*</Form.Group>*/}
        </div>
          // Дописать парметры файл открытия задачи и стили добавить выбор по пользователю и проверку
      )} else { setMailTypeRequest("пусто")
            setMailTypeWork("пусто")
            setMailDegreeExposure("пусто")
            return null}
    }
    // console.log(localDate)
    const handleCloseNull = () => {
              setShow(false);

    }
    const handleClose = () => {
        setShow(false);
        const data = [{
            "id": uuid,
            "refUsers": person,
            "due_date": localDate,
            "formulation_task": inputFormulation,
            "counterparty": inputUser,
            "counterparty_inn": inputUserInn,
            "status": status,
            "date": localDateToday,
            // "priority": inputPriority,
            "author" : authorName,
            "mailDegreeValue": mailDegreeValue,
            "mailTypeRequest": mailTypeRequest,
            "mailTypeWork": mailTypeWork,
            "mailDegreeExposure": mailDegreeExposure
        }]
        setLoading(true)



        api.post('v1/newTask/', data)
            .then(res => {
                setLoading(false)
                // alert("Все ок")
                fetchDataTasks()
                taskCompletedOpen()
            })
            .catch(err => {
                if (err.response) {
                    setLoading(false)
                    alert("Что-то пошло не так")
                } else {
                    setLoading(false);
                    fetchDataTasks()
                    taskCompletedOpen();
                }
            })



    }

    const handleShow = () => setShow(true);



    return (
        <>
          <Modal show={taskCompleted} onHide={taskCompletedClose} animation={true}>
        <Modal.Header className={"TaskComp"} closeButton>
          {/*<Modal.Title style={{color: "#7c7878"}} >Уведомление</Modal.Title>*/}
        </Modal.Header>

        <Modal.Body className={"TaskComp"}>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg><h1 className={"h1TaskComp"}>Задача успешно создана!</h1></Modal.Body>

        <Modal.Footer className={"TaskComp"}>
          <Button  onClick={taskCompletedClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
            <a onClick={handleShow}>
                СОЗДАТЬ ЗАДАЧУ <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 19V5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19Z" stroke="currentColor"  />
                <path d="M2 7L22 7" stroke="currentColor"   stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 14H12M15 14H12M12 14V11M12 14V17" stroke="currentColor"   stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            </a>

            <Modal show={show}
                   size={"lg"}
                   onHide={handleCloseNull}>
                <Modal.Header closeButton>
                    <Modal.Title>Новая задача</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                            <Form.Group className={"newTaskElement"} style={{width: "150px"}}>
                                <Form.Label htmlFor="disabledTextInput">Контрагент</Form.Label>
                                <Form.Control autoFocus
                                               placeholder={res.title} disabled />
                            </Form.Group>
                        <Form.Group className={"newTaskElement"} style={{width: "300px"}}>
                            <Form.Label htmlFor="disabledTextInput">Дата окончания</Form.Label>
                            <DatePicker required  defaultValue={new Date()} includeTime onChange={value => setInputDueDate(value)}/>


                            {/*<DatePicker value={new Date()} includeTime onChange={value => setInputDueDate(value)}/>*/}

                        </Form.Group>

                    <Form.Group className={"newTaskElement"} controlId="exampleForm.ControlInput1">
                            <Form.Label>Приоритет</Form.Label>
                            <select
                                autoFocus
                                value={inputPriority} onChange={e => setInputPriority(e.target.value)}
                                style={{width: "150px", borderColor: "black"}}
                            >
                                <option value="Критический">Критический</option>
                                <option value="Высокий">Высокий</option>
                                <option value="Средний">Средний</option>
                                <option value="Низкий">Низкий</option>
                                <option value="Плановый">Плановый</option>
                            </select>

                        </Form.Group>
                        <Form.Group className="mb-3" style={{width: "200px"}}>
                            <Form.Label htmlFor="disabledTextInput">Статус</Form.Label>
                            <Form.Control autoFocus
                                          placeholder={status} disabled />
                        </Form.Group>
                        <MailNewTask/>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                            value={inputPriority} onChange={e => setInputFormulation(e.target.value)}

                        >

                            <Form.Label>Формулировка задачи</Form.Label>
                            <Form.Control  as="textarea" rows={3}/>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseNull}>
                        Отмена
                    </Button>
                    <Button type={"submit"}  variant="primary" onClick={handleClose}>
                        Создать
                    </Button>
                </Modal.Footer>
            </Modal>
            <Loader isLoading={loading}/>
        </>
    );
}
export default NewTask