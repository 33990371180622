
import React from "react";
import {Accordion} from "react-bootstrap";
import "../Style/style.css"
// import
import taskNav from "../../../assets/screenshots/taskNav.png"
import NewTaskButton from "../../../assets/screenshots/NewTask.png"
import NewTaskForm from "../../../assets/screenshots/formNewTask.png"
import LoadeerImg from "../../../assets/screenshots/loader.png"
import TaskCompl from "../../../assets/screenshots/taskCobpl.png"
import TaskListImg from "../../../assets/screenshots/taskList.jpg"
import TaskForm from "../../../assets/screenshots/OpenTask.png"
import Comment from "../../../assets/screenshots/NewComment.png"
import NewComment from "../../../assets/screenshots/NewCommentCompl.png"
import ProfileNav from "../../../assets/screenshots/profileNav.png"
import ProfileBody from "../../../assets/screenshots/profileBody.png"
import passSetting from "../../../assets/screenshots/settings.png"
import newPass from "../../../assets/screenshots/passNew.png"
import Profile from "../../../assets/screenshots/profileRef.png"
import ReportsNav from "../../../assets/screenshots/reportsNav.png"
import ReportsPage from "../../../assets/screenshots/ReportsBody.png"
import ReportsDate from "../../../assets/screenshots/ReportsBodyData.png"
import ReportsDateForm from "../../../assets/screenshots/taskDataFilter.png"
import NawDoc from "../../../assets/screenshots/taskDoc.png"
import CheckDoc from "../../../assets/screenshots/Chek.png"
import CheckDocAll from "../../../assets/screenshots/fbbe992ff528416fc7758e52cf4c2d26.png"
import ReportOpen from "../../../assets/screenshots/ReportsOpen.png"
import Filter from "../../../assets/screenshots/statusFilter.png"
import BodyDoc from "../../../assets/screenshots/docBody.png"
import Serch from "../../../assets/screenshots/ShertchTask.png"






const ContentManual = () => {
    return(
        <div>
            {/*<h1 className={"manualH1"}>Как пользоваться личным кабинетом?</h1>*/}
            <h2 className={"manualH2"}>Помощь</h2>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Как создать задачу?</Accordion.Header>
                    <Accordion.Body>
                        <p className={"manualP"}>1. Перейдите в раздел задачи на са сайте</p>
                        <img className={"imgScren"} src={taskNav}/>
                        <p className={"manualP"}>2. Нажмите кнопку создать задачу</p>
                        <img className={"imgScren"}  src={NewTaskButton}/>
                        <p className={"manualP"}>3. Откроется форма создания задачи</p>
                        <img style={{width: "60%"}} className={"imgScren"}  src={NewTaskForm}/>
                        <p className={"manualP"}>4. Заполните активные поля и нажмите кнопку создать</p>
                        <p className={"manualP"}>5. Появиться значек загрузки, дождитесь окончания создания задачи</p>
                        <img style={{width: "40%"}} className={"imgScren"}  src={LoadeerImg}/>
                        <p className={"manualP"}>6. Задача создана</p>
                        <img style={{width: "40%"}} className={"imgScren"}  src={TaskCompl}/>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>Как открыть содержимое задачи?</Accordion.Header>
                    <Accordion.Body>
                        <p className={"manualP"}>1. Перейдите в раздел задачи на са сайте</p>
                        <img className={"imgScren"} src={taskNav}/>
                        <p className={"manualP"}>2. При желании можно указать фильтр по статусам/датам</p>
                        <img style={{width: "60%"}} className={"imgScren"} src={Filter}/>
                        <p className={"manualP"}>3. Поиск по формулировке задачи</p>
                        <img style={{width: "60%"}} className={"imgScren"} src={Serch}/>
                        <p className={"manualP"}>4. Нажмите на желаемую задачу из списка</p>
                        <img className={"imgScren"} src={TaskListImg}/>
                        <p className={"manualP"}>5. В открывшемся окне вы увидите содержимое вашей задачи</p>
                        <img className={"imgScren"} src={TaskForm}/>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Как создать новый комментарий к задаче?</Accordion.Header>
                    <Accordion.Body>
                        <p className={"manualP"}>1. Перейдите в раздел задачи на са сайте</p>
                        <img className={"imgScren"} src={taskNav}/>
                        <p className={"manualP"}>2. Нажмите на желаемую задачу из списка</p>
                        <img className={"imgScren"} src={TaskListImg}/>
                        <p className={"manualP"}>3. В открывшемся окне вы увидите содержимое вашей задачи</p>
                        <img className={"imgScren"} src={TaskForm}/>
                        <p className={"manualP"}>4. внизу содержимого вы увидите новый раздел комментарий</p>
                        <img className={"imgScren"} src={Comment}/>
                        <p className={"manualP"}>5. Напишите комментарий и нажмите кнопку создать</p>
                        <img className={"imgScren"} src={NewComment}/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                <Accordion.Header>Как изменить пароль на собственный?</Accordion.Header>
                <Accordion.Body>
                    <p className={"manualP"}>1. Нажмите на раздел профиль на сайте</p>
                    <img className={"imgScren"} src={ProfileNav}/>
                    <p className={"manualP"}>2. Нажмите на кнопку настройки</p>
                    <img style={{width: "30%"}} className={"imgScren"} src={ProfileBody}/>
                    <p className={"manualP"}>3. Нажмите на кнопку сменить пароль</p>
                    <img style={{width: "60%"}} className={"imgScren"} src={passSetting}/>
                    <p className={"manualP"}>4. Введите желаемый пароль два раза</p>
                    <img style={{width: "60%"}} className={"imgScren"} src={newPass}/>
                    <p className={"manualP"}>5. Нажмите кнопку подтвердить</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Как изменить организацию?(Если у вас больше одной)</Accordion.Header>
                <Accordion.Body>
                    <p className={"manualP"}>1. Нажмите на раздел профиль на са сайте</p>
                    <img className={"imgScren"} src={ProfileNav}/>
                    <p className={"manualP"}>2. Нажмите на кнопку настройки</p>
                    <img style={{width: "30%"}} className={"imgScren"} src={ProfileBody}/>
                    <p className={"manualP"}>3. Нажмите на кнопку сменить организацию</p>
                    <img style={{width: "60%"}} className={"imgScren"} src={passSetting}/>
                    <p className={"manualP"}>4. Выберите желаемую организацию</p>
                    <img style={{width: "60%"}} className={"imgScren"} src={Profile}/>
                    <p className={"manualP"}>5. Нажмите кнопку выбрать</p>
                </Accordion.Body>
            </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Как сформировать отчеты?</Accordion.Header>
                    <Accordion.Body>
                        <p className={"manualP"}>1. Нажмите на раздел отчеты на са сайте</p>
                        <img className={"imgScren"} src={ReportsNav}/>
                        <p className={"manualP"}>2. Перед вами откроется страница отчетов</p>
                        <img style={{width: "70%"}} className={"imgScren"} src={ReportsPage}/>
                        <p className={"manualP"}>3. Нажмите на кнопку указать период отчета</p>
                        <img style={{width: "60%"}} className={"imgScren"} src={ReportsDateForm}/>
                        <p className={"manualP"}>4. Выберите желаемый тип отчета, при необходимости укажите дополнительные параметры</p>
                        <img style={{width: "60%"}} className={"imgScren"} src={ReportsDate}/>
                        <p className={"manualP"}>5. Нажмите сформировать желаемый отчет</p>
                    </Accordion.Body>
                </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>Как получить документы на почту?</Accordion.Header>
                <Accordion.Body>
                    <p className={"manualP"}>1. Нажмите на раздел документы на са сайте</p>
                    <img className={"imgScren"} src={NawDoc}/>
                    <p className={"manualP"}>2. Перед вами откроется страница cо списком ваших документов</p>
                    <img style={{width: "70%"}} className={"imgScren"} src={BodyDoc}/>
                    <p className={"manualP"}>3. Вы можете указать период за который желаете получить нужные документы</p>
                    <img style={{width: "60%"}} className={"imgScren"} src={ReportsDateForm}/>
                    <p className={"manualP"}>4. Для того чтоб подробно развернуть документ кликните по его названию</p>
                    <img style={{width: "60%"}} className={"imgScren"} src={ReportOpen}/>
                    <p className={"manualP"}>5. Для того чтоб воспользоваться кнопкой отправить документы на почту нужно выбрать как минимум один документ</p>
                    <img style={{width: "20%"}} className={"imgScren"} src={CheckDoc}/>
                    <p className={"manualP"}>6. Для удобства также предусмотрена кнопка выбрать все документы а также свернуть/показать содержимое счетов</p>
                    <img style={{width: "60%"}} className={"imgScren"} src={CheckDocAll}/>
                    <p className={"manualP"}>7. Нажмите на кнопку отправить на почту и наши специалисты пришлют выбранные документы на ваш почтовы адрес указанный в профиле</p>
                </Accordion.Body>
            </Accordion.Item>

            </Accordion>
        </div>
    )
}
export default ContentManual