import React, {useContext} from "react";
import "./Search.css"
import {Button, Form} from "react-bootstrap";
import AuthContext from "../../../context/AuthContext";
import {useState} from "react";

const Search = () => {
        const {searchInput, setSearchInput} = useContext(AuthContext);
                const [searchInputLocal, setSearchInputLocal] = useState("");
                const SerchGlobal = () => {
                  setSearchInput(searchInputLocal)
                }
                  const SerchGlobalClear = () => {
                    setSearchInputLocal("")
                  setSearchInput("")
                }

    return(
         <div className={"inputBorder"}>
         {/*//     <input  />*/}
         {/*// </div>*/}
         <Form.Group  className="mb-3">
        <Form.Control className={"input"}
                        value={searchInputLocal}
                        onChange={(e) => setSearchInputLocal(e.target.value)}
        />

         </Form.Group>
<Button className={"buttonSearchDel"} onClick={SerchGlobalClear}>
<svg width="26" height="26" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 15h4.5v-1.5H15V15Z"></path>
  <path d="M18 21h4.5v-1.5H18V21Z"></path>
  <path d="M16.5 18H21v-1.5h-4.5V18Z"></path>
  <path d="M12.752 15a3.671 3.671 0 0 0-1.803-3.13L16.5 2.25l-1.298-.75-5.682 9.845a4.273 4.273 0 0 0-3.932 1.127c-2.808 2.708-2.59 9.04-2.58 9.308a.75.75 0 0 0 .75.72H15a.75.75 0 0 0 .45-1.35c-2.655-1.992-2.699-6.11-2.699-6.15Zm-3.804-2.252A2.333 2.333 0 0 1 11.253 15c0 .029.002.156.013.352L6.84 13.384a2.85 2.85 0 0 1 2.107-.636ZM11.588 21a3.9 3.9 0 0 1-1.088-2.25H9c.054.798.303 1.571.726 2.25H8.06a12.462 12.462 0 0 1-.559-3H6c.049 1.016.216 2.023.5 3h-2c.023-1.377.218-4.42 1.352-6.415l5.65 2.513A9.769 9.769 0 0 0 13.198 21h-1.61Z"></path>
</svg>
</Button>

         <Button className={"buttonSearch"} onClick={SerchGlobal}>
                  Поиск
                 <svg width="20" height="26" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                 <path d="M14.25 2.25c-4.134 0-7.5 3.366-7.5 7.5 0 1.796.63 3.442 1.688 4.734L2.46 20.461l1.078 1.078 5.977-5.977a7.442 7.442 0 0 0 4.734 1.688c4.134 0 7.5-3.366 7.5-7.5s-3.366-7.5-7.5-7.5Zm0 1.5c3.322 0 6 2.678 6 6s-2.678 6-6 6-6-2.678-6-6 2.678-6 6-6Z"></path>
                 </svg>
              </Button>
    </div>
    )
}


export default Search