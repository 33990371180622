import {useState, useContext} from "react";
import React from "react";
import {useForm} from "react-hook-form";
import "../Style/Auth.css"
import {useHistory, useNavigate} from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import Background from "../Background/Background";

function Update() {

    const {registerUser} = useContext(AuthContext);


    const {
        register, formState: {
            errors
        }, handleSubmit, reset,
    } = useForm({
        mode: "onBlur"
    });
    const history = useNavigate();

    const onSubmit = (data) => {
        const username = data.username;
        const author = data.author;

        registerUser(username, author);
        alert("Ожидайте пароль на телефон")
        history("login");
    }

    return (
        <div className={"context"} style={{backgroundColor: "#eeeaea"}}>
            <form className={"form"} onSubmit={handleSubmit(onSubmit)}>
                <div style={{justifyContent: "center", textAlign: "center"}}>
                    <h2 className={"h2"}>Регистрация:</h2>

                    <label style={{textAlign: "left", color: "#2a2929"}}>
                        ФИО(логин):
                        <input className={"inputPhone"}
                               {...register('author', {
                                   required: '⛔️Поле обязательно к заполнению',

                                   pattern: {
                                       value: /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,}|[А-ЯA-Z][\x27а-яa-z]{1,}\-([А-ЯA-Z][\x27а-яa-z]{1,}|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]{1,}(\040[А-ЯA-Z][\x27а-яa-z]{1,})?$/,
                                       message: "Введите полное ФИО"

                                   }, maxLength: {
                                       value: 100, message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   }
                               })}
                        />
                    </label>
                    <label style={{textAlign: "left", color: "#2a2929"}}>
                        Номер телефона:
                        <input className={"inputPhone"}
                               {...register('username', {
                                   required: '⛔️Поле обязательно к заполнению', minLength: {
                                       value: 11, message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   },

                                   maxLength: {
                                       value: 11, message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   }
                               })}
                        />
                    </label>

                    <div>
                        {errors?.username && <p className={"p"}>{errors?.username?.message || "Error!"}</p>}
                    </div>


                    <button className={"buttonFormReg"} type="submit">Получить пароль по SMS</button>
                    <div style={{width: "80%", justifyContent: "center", marginLeft: "15%", marginTop: "10%"}}>
                        <p style={{textAlign: "left"}}>
                            В случае если вы не являетесь клиентом ООО "НЕКСОФТ" ваша заявка будет отклонена
                        </p>
                    </div>

                </div>
            </form>
            <Background/>
        </div>

    );
}

export default Update;
