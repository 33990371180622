import React from "react";
import "./upScroll.css"

const UpScroll = () => {
        return (
            <a className={"upScroll"} href={"#"}>
            <svg width="56" height="56" fill="none" stroke="#0061ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z"></path>
                <path d="m16 12-4-4-4 4"></path>
                <path d="M12 16V8"></path>
            </svg>
            </a>
        )

}

export default UpScroll