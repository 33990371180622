import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import useAxios from "../../../utils/useAxios";
import "react-widgets/styles.css";

const UpPass = () => {
    const phone = localStorage.getItem("phone")

    const author = localStorage.getItem("author")
    let authorName = author.split("+").join(" ");
        const api = useAxios();

    const [inputPassStart, setInputPassStart] = useState('')
    const [inputPassDefolt, setInputPassDefolt] = useState('')

    const [show, setShow] = useState(false);
    const handleCloseNull = () => {
              setShow(false);

    }
    const handleClose = () => {
        if (inputPassStart === inputPassDefolt){

        const data = {
        "passuser": phone,
        "updpas": inputPassDefolt,
        "author": author,
        }


       api.post('v1/uppas/', data)
       alert("Пароль успешно изменён")
       setShow(false);

} else {alert("Пароли не совпадают")
                setShow(true);

}



    }

    const handleShow = () => setShow(true);



    return (
        <>
            <a onClick={handleShow}>
  <a onClick={handleShow}>
                Сменить пароль
            </a>
            </a>

            <Modal show={show}
                   onHide={handleCloseNull}>
                <Modal.Header closeButton>
                    <Modal.Title>Изменить пароль</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
      <Form.Group onChange={e => setInputPassStart(e.target.value)}
         className="mb-3" controlId="formBasicPassword">
        <Form.Label>Введите новый пароль</Form.Label>
        <Form.Control type="password" placeholder="Password" />
      </Form.Group>
      <Form.Group onChange={e => setInputPassDefolt(e.target.value)}
          className="mb-3" controlId="formBasicPassword">
        <Form.Label>Подтвердите пароль</Form.Label>
        <Form.Control type="password" placeholder="Password" />
      </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseNull}>
                        Отмена
                    </Button>
                    <Button type={"submit"}  variant="primary" onClick={handleClose}>
                        Подтвердить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default UpPass