import {useState, useContext} from "react";
import React from "react";
import {useForm} from "react-hook-form";
import "../Style/Auth.css"
import {useHistory, useNavigate} from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import Background from "../Background/Background";

function Registration() {

    const {registerUser, passCompletedOpen} = useContext(AuthContext);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).replace(/\s+$/, '');
    }

    const {
        register, formState: {
            errors
        }, handleSubmit, reset,
    } = useForm({
        mode: "onBlur"
    });
    const history = useNavigate();

    const onSubmit = (data) => {
        if (data.username[0] === "+"){
            data.username = data.username.substring(1);
        }
        data.name = capitalizeFirstLetter(data.name)
        data.middleName = capitalizeFirstLetter(data.middleName)
        data.surname = capitalizeFirstLetter(data.surname)


        const username = data.username;
        const author = data.surname + " " + data.name + " " + data.middleName;

        registerUser(username, author);
        passCompletedOpen()
        history("/login");
    }

    return (<div className={"context"} style={{backgroundColor: "#eeeaea"}}>
            <form className={"form"} onSubmit={handleSubmit(onSubmit)}>
                <div style={{justifyContent: "center", textAlign: "center"}}>
                    {/*<h2 className={"h2"}>Получить пароль:</h2>*/}

                    <label style={{textAlign: "left", color: "#2a2929", marginTop: "45px"}}>
                        Фамилия:
                        <input placeholder="Иванов" className={"inputPhone"}
                               {...register('surname', {
                                   required: '⛔️Поле обязательно к заполнению',

                                   // pattern: {
                                   //
                                   //     // value: /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,}|[А-ЯA-Z][\x27а-яa-z]{1,}\-([А-ЯA-Z][\x27а-яa-z]{1,}|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]{1,}(\040[А-ЯA-Z][\x27а-яa-z]{1,})?$/,
                                   //     // message: "Введите полное ФИО"
                                   //
                                   // }, maxLength: {
                                   //     value: 100, message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   // }
                               })}
                        />
                    </label>
                    <label style={{textAlign: "left", color: "#2a2929"}}>
                        Имя:
                        <input placeholder="Иван" className={"inputPhone"}
                               {...register('name', {
                                   required: '⛔️Поле обязательно к заполнению',

                                   // pattern: {
                                   //     // value: /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,}|[А-ЯA-Z][\x27а-яa-z]{1,}\-([А-ЯA-Z][\x27а-яa-z]{1,}|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]{1,}(\040[А-ЯA-Z][\x27а-яa-z]{1,})?$/,
                                   //     // message: "Введите полное ФИО"
                                   //
                                   // }, maxLength: {
                                   //     value: 100, message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   // }
                               })}
                        />
                    </label>
                    <label style={{textAlign: "left", color: "#2a2929"}}>
                        Отчество:
                        <input placeholder="Иванович" className={"inputPhone"}
                               {...register('middleName', {
                                   required: '⛔️Поле обязательно к заполнению',

                                   // pattern: {
                                   //     // value: /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,}|[А-ЯA-Z][\x27а-яa-z]{1,}\-([А-ЯA-Z][\x27а-яa-z]{1,}|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]{1,}(\040[А-ЯA-Z][\x27а-яa-z]{1,})?$/,
                                   //     // message: "Введите полное ФИО"
                                   //
                                   // }, maxLength: {
                                   //     value: 100, message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   // }
                               })}
                        />
                    </label>
                    <label style={{textAlign: "left", color: "#2a2929"}}>
                        Номер телефона:
                        <input placeholder="+7"  className={"inputPhone"}
                             {...register('username', {
                                   // required: '⛔️Поле обязательно к заполнению', minLength: {
                                   //     value: 11, message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   // },
                                   minLength: {
                                       value: 11, message: "Некорректный номер"
                                   },

                                   maxLength: {
                                       value: 12, message: "Некорректный номер"
                                       // message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   }

                               })}
                        />
                    </label>

                    <div>
                        {errors?.username && <p className={"p"}>{errors?.username?.message || "Error!"}</p>}
                    </div>


                    <button className={"buttonFormReg"} type="submit">Получить пароль по SMS</button>
                    <div style={{width: "80%", justifyContent: "center", marginLeft: "10%", marginTop: "10%"}}>
                        <p className={"FormAnataation"}>
                            В случае если вы не являетесь клиентом <br/> ООО "НЕКСОФТ" ваша заявка будет отклонена
                        </p>
                    </div>

                </div>
            </form>
            <Background/>
        </div>

    );
}

export default Registration;
