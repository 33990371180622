import {useContext, useEffect, useState} from "react";
import React from "react";
import AuthContext from "../../../context/AuthContext";
import SchetsList from "../Schet/SchetsList";
import DateDrop from "../../TaskList/DateDrop/DateDrop";
import SendToMailButton from "../BottonPost/SendToMailButton";
import Errop from "../../../assets/imges/error.svg"
import Loader from "../../loader/loader";
import ShowAllButton from "../OpenString/ShowAllButton";
import SelectAllButton from "../SelectAllButton/SelectAllButton";
import ReportsDate from "../../Reports/ReportsDate";
const DocTab = () => {
    const {resDataDocs, fetchDataDocs} = useContext(AuthContext);
    const {taskDateStart} = useContext(AuthContext);
    const {taskDateFinish} = useContext(AuthContext);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        fetchDataDocs();
        }, [taskDateStart, taskDateFinish]);
if (!resDataDocs) {
    return (
        <>
            <Loader isLoading={loading}/>
        </>
    )
} else {
    return (
    <>
        <div style={{marginLeft: "20px", marginBottom: "20px"}}><ReportsDate/></div>
        <SendToMailButton/>
        <SelectAllButton/>
        <ShowAllButton/>
        <SchetsList/>
    </>
)}
}
export default DocTab;