import React, {useCallback} from "react";
import {Card, Form, Modal} from "react-bootstrap";
import {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import NewComment from "../ExampleDataForm/newComment";
import ExampleData from "../ExampleDataForm/exampleData";
import RatingBar from "../RatingBar/RatingBar";
import { StyledEngineProvider } from '@mui/material/styles';
import FilePost from "../../FilePost/FilePost";
import HistoryTask from "../HistoryTask/HistoryTask";

export const EntryForm = () => {

    const {somePopupData, setSomePopupData} = useContext(AuthContext);
    const {person} = useContext(AuthContext);

    const someHideCallback = useCallback(() => {
        setSomePopupData(null);
        if (localStorage.getItem("taskID")){
            localStorage.removeItem("taskID");

        }
    }, []);


    if (!somePopupData) {
        return null;
    }

    const {
        id,
        numberTask,
        counterparty,
        counterparty_inn,
        cost_of_work,
        formulation_task,
        status,
        executor,
        date,
        due_date,
        get_started,
        author,
        mailTypeRequest,
        mailTypeWork,
        mailDegreeExposure,
        mailDegreeValue,
    } = somePopupData;

    function ABBA(s) {
        const options = {
            // era: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            // weekday: 'long',
            timezone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        // res[i].date
        let inp = new Date(s)
        let out = inp.toLocaleString("ru", options)
        out = out.substring(0, out.indexOf(','));

        return out;
    }


    let due_dateRef = ABBA(due_date);
    let get_startedRef = ABBA(get_started);
    let dateRef = ABBA(date);


    const NewcommentRender = () => {
        if (status === "Выполнена") {
            return null;
        } else if (status === "Аннулировано") {
            return null;
        } else {
            return (<NewComment id={id}/>)
        }
    }


// props.taskId.get_started = ABBA(props.taskId.get_started);

    const MailCounterparty = () => {
        if (person == "61ade4bc-9464-11e7-aafd-40167eaae633") {
            return (
                <>
                    <div>
                        <div className={"pArg"}>Вид запроса:</div>
                        {mailTypeRequest}</div>
                    <div>
                        <div className={"pArg"}>Степень воздействия:</div>
                        {mailDegreeExposure}</div>
                    <div>
                        <div className={"pArg"}>Вид работ:</div>
                        {mailTypeWork}</div>
                    <div>
                        <div className={"pArg"}>Степень воздействия значение :</div>
                        <div className={"pArg3"}>
                            {mailDegreeValue}
                        </div>
                    </div>
                </>
            )
        } else {
            return null
        }
    }

    const RatingBlock = (closeModal) =>{
        return (
            <div>
                <StyledEngineProvider injectFirst>
                    <RatingBar closeModal={closeModal}/>
                </StyledEngineProvider>
            </div>
        )
    }

    return (
        <Modal
            // style={{width: "320px"}}
            className={"ModalwidthFormCard"}
            size="lg"
            show
            onHide={someHideCallback}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton style={{backgroundColor: "#224cbd", color: "#ffffff"}}>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className={"headerTask"}>
                        Задача № {numberTask}
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className={"formAll"}>
                    {/*<ListGroup variant="flush">*/}
                    <style type="text/css">
                        {`
     .formAll{
     width: 770px
    }
    .widthForm{
     width:100%;
    }
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .formAll{
     width: 100%
    }
    .widthForm{
        width:100%;
    }
    .widthFormCard{
    padding: 0;
    width:100%;
    }
    .ModalwidthFormCard{
    padding: 0;
    width:100%;
    }
    }
    `}
                    </style>
                    <Card
                        bg={"light"}
                        key={"primary"}
                        text="dark"
                        border={"primary"}
                        className={"widthFormCard"}
                    >
                        <div className={"widthFormCard"}>
                            <Card.Body>
                                {/*<Card.Title>Card Title</Card.Title>*/}
                                {/*<Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>*/}
                                <Card.Text>
                                    <div style={{fontSize: "11pt"}}>
                                        <div>
                                            <div className={"pArg"}>Контрагент:</div>
                                            {counterparty}</div>
                                        <div>
                                            <div className={"pArg"}>ИНН:</div>
                                            {counterparty_inn}</div>
                                        <div>
                                            <div className={"pArg"}>Стоимость работы:</div>
                                            {cost_of_work + " ₽"}</div>
                                        <div>
                                            <div className={"pArg"}>Статус:</div>
                                            <div className={"pArg3"}>
                                                {status}
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"pArg"}>Исполнитель:</div>
                                            {executor}
                                        </div>
                                        <div>
                                            <div className={"pArg"}>Дата постановки:</div>
                                            {dateRef}
                                        </div>
                                        <div>
                                            <div className={"pArg"}>Срок выполнения задачи:</div>
                                            {due_dateRef}
                                        </div>
                                        <div>
                                            <div className={"pArg"}>План-дата выполнения:</div>
                                            {due_dateRef}
                                        </div>

                                        <div>
                                            <div className={"pArg"}>Начало работы:</div>
                                            {get_startedRef}</div>
                                        <div>
                                            <div className={"pArg"}>Автор:</div>
                                            {author}</div>
                                    </div>
                                    <MailCounterparty/>
                                    <div>
                                        <h3 style={{
                                            fontSize: "12pt",
                                            fontFamily: "Arial",
                                            marginTop: "15px"
                                        }}> Формулировка
                                            задачи: </h3>
                                        <Form.Group className="widthForm" controlId="exampleForm.ControlTextarea1"
                                                    style={{marginTop: "20px"}}>
                                            <Form.Control as="textarea" readOnly rows={7}
                                                          value={formulation_task}/>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <FilePost status={status} id={id}/>
                                        <StyledEngineProvider injectFirst>
                                            {
                                                (status === "Выполнена") ?  <RatingBar closeModal={someHideCallback}/> : null
                                            }
                                        </StyledEngineProvider>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </div>

                    </Card>
                    <div style={{marginTop: "20px"}}>
                        {/*{loading}*/}
                        {/*{commentListRender}*/}
                        <ExampleData id={id}/>
                        {/*<NewComment id={id}/>*/}
                        <NewcommentRender/>
                        <HistoryTask numberTask={numberTask}/>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EntryForm;