import React from "react";
import "../components/Intro/Style/Auth.css"
import Update from "../components/Intro/Update/Update";

function ReversUserPage() {

    return (
      <Update/>
    );
}

export default ReversUserPage;
