import React, {useContext} from "react";
import DateDrop from "../../TaskList/DateDrop/DateDrop";
import "../Style/doc.css"
import {ABBA} from "../../Helpers/Helpers";
import Check from "../Cek/Check";
import AuthContext from "../../../context/AuthContext";

const Akt = (props) => {
    const {setSomePopupDataDocs} = useContext(AuthContext);
    const {resDataDocs} = useContext(AuthContext);

    let batRtu = []
    for (let i = 0; i < resDataDocs.Akty.length; i++){
        if (resDataDocs.Akty[i].nomerdocschet === props.id){
            batRtu.push(resDataDocs.Akty[i])
        }
    }
    const typedoc = "akt"
    const someCallback = (docid) => {
        setSomePopupDataDocs({
            ...docid,
            typedoc
        });
    };


    const rtuListRender = batRtu.map(docid =>
        <div style={{display: "flex"}}>
            <Check typeSc={"akt"} doc={docid}/>
            <div className={"shetsActiv"} style={{cursor: "pointer"}} onClick={() => someCallback(docid)}>{docid.namedoc} {docid.nomerdoc} от {ABBA(docid.datedoc)} на сумму: {docid.summadoc} руб.</div>
        </div>
            )

    return (
        <>
            {rtuListRender}
        </>
    );


}
export default Akt