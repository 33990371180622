import * as React from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import styles from "./RatingBar.module.css";
import {Form} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../context/AuthContext";
import axios from "axios";
import _ from 'lodash';
import useAxios from "../../../utils/useAxios";

// Стилизация иконок
const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

// Иконки смайлов для рейтинга
const customIcons: {
    [index: string]: {
        icon: React.ReactElement;
        label: string;
        value: number;
    };
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" />,
        label: 'Very Dissatisfied',
        value: 1
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" />,
        label: 'Dissatisfied',
        value: 2
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" />,
        label: 'Neutral',
        value: 3
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" />,
        label: 'Satisfied',
        value: 4
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" />,
        label: 'Very Satisfied',
        value: 5
    },
};

function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}
export default function RatingBar({closeModal}) {
    const api = useAxios();
    const [showMessage, setShowMessage] = useState(false);
    const {somePopupData, setSomePopupData} = useContext(AuthContext); // Получаем данные об открытой задаче
    const {
        id,
        refUsers
    } = somePopupData;
    // Формируем объек данных для отправки на сервер
    const [dataRating, setDataRating] = useState({
        "refTask": id,
        "clientId": refUsers,
        "rating": 1,
        "comment": " ",
        "date": new Date(),
    });

    const [ratingCheck, setRatingCheck] = useState([])
    const [rating, setRating] = useState(1)


    // Функция вывода сообщения "Спасибо за оценку" и закрытие модального окна
    const handleRatingChange = (newRating) => {
        setShowMessage(true);

        // Скрытие модального окна через 3 секунды
        setTimeout(() => {
            closeModal();
            setShowMessage(false);
        }, 3000);
    };

    // Отправка данных на сервер
    const sendDataToServer = (dataRating) => {
        console.log("Отправка", dataRating)
        api.post(`/v1/newRating/`, dataRating)
            .then(function (response) {
                // Обработка успешного ответа от сервера
                console.log('Успешно отправлено на сервер:', response);
            })
            .catch(function (error) {
                // Обработка ошибки
                console.error('Ошибка при отправке на сервер:', error);
            });
    }

    // Выбор рейтинга и записывание значения оценки в объект данных
    function onChoiceRating (rating){
        setRating(rating)
        const updatedDataRating = {...dataRating,
        rating: rating}
        setDataRating(updatedDataRating);
    }

    // Получение  и записывание значения комментария в объект данных
    // debounce - lodash, чтобы обновление значения происходило только через 300ms. Избегаем многократного выполнения функции
    const onCommentUpdate = _.debounce((text) => {
        const updatedDataRating = {...dataRating,
            comment: text}
        setDataRating(updatedDataRating);
    }, 300)

    // Обработка функций который выполняются при нажатии на кнопку "Отправить оценку"
    function onClickButton(e) {
        e.preventDefault();
        handleRatingChange();
        sendDataToServer(dataRating);
    }

     useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await api.get(`/v1/rating/?refTask=${id}`);
            setRatingCheck(response.data.results === [] ? [] : response.data.results); // Update the state with the fetched data
              console.log(response.data.results)
          } catch (error) {
            setRatingCheck([]);
          }
        };

        fetchData(); // Call the async function
      }, [id]); // Empty dependency array to run this effect only once

    return (
        <div>
            {Boolean(ratingCheck[0]) ?
            <div>
                    <div className={styles.ratingBlock}>
                        <div>Оценка зaдачи:</div>
                        <StyledRating
                            className={styles.lastBlock}
                            name="highlight-selected-only"
                            defaultValue={ratingCheck[0].rating}
                            disabled={true}
                            IconContainerComponent={IconContainer}
                            highlightSelectedOnly
                        />
                    </div>
                    <div >
                        <Form>
                            <Form.Group className="widthForm commentFromRating" controlId="exampleForm.ControlTextarea2 validationCustom01" style={{marginTop: "20px"}} >
                                <Form.Label style={{
                                    fontSize: "12pt",
                                    fontFamily: "Arial"
                                }}>Комментарий к оценке задачи:</Form.Label>
                                <Form.Control disabled={true} value={ratingCheck[0].comment} required as="textarea" rows={2}/>
                                <Form.Label style={{
                                    fontSize: "12pt",
                                    fontFamily: "Arial"
                                }}>Дата: {ratingCheck[0].date}</Form.Label>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                :

        <div>
            {
                !showMessage ?
                    <div>
                    <div className={styles.ratingBlock}>

                        <div>Оценка задачи:</div>
                        <StyledRating
                            className={styles.lastBlock}
                            name="highlight-selected-only"
                            defaultValue={1}
                            IconContainerComponent={IconContainer}
                            onChange={(event, newValue) => {
                                onChoiceRating(newValue)
                            }
                            }
                            highlightSelectedOnly
                        />
                        <div style={{marginLeft: "10px", fontSize: "12pt",
                                    fontFamily: "Arial"}}>
                         {rating == 1 ? "Очень плохо" : rating == 2 ? "Плохо" : rating == 3 ? "Удовлетворительно" : rating == 4 ? "Хорошо" : "Отлично"}
                        </div>
                    </div>
                            <div >
                        <Form>
                            <Form.Group className="widthForm commentFromRating" controlId="exampleForm.ControlTextarea2 validationCustom01" style={{marginTop: "20px"}} >
                                <Form.Label style={{
                                    fontSize: "12pt",
                                    fontFamily: "Arial"
                                }}>Комментарий к оценке задачи:</Form.Label>
                                <Form.Control required as="textarea" rows={2} onChange={(e)=> onCommentUpdate(e.target.value)}/>
                            </Form.Group>
                        </Form>

                    </div>
                    <button onClick={(e) => onClickButton(e)} className={styles.animate} >Отправить оценку</button>
                </div> :
                <div className={styles.fadeIn}>Спасибо за оценку задачи</div>
            }
        </div>
            }
        </div>
    );
}
