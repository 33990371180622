
import React from "react";
import LeftColumn from "../components/Navigation/NavigationLeft/NavigationLeft";
import ContentManual from "../components/manual/manualBody/ContentManual";

const Home = () => {

    return (
        <>
            <LeftColumn/>
            {/*<HomeBody/>*/}
            <div className={"tabelContent"}>
            <ContentManual/>
            </div>

        </>
    )
}
export default Home