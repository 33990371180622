import React from "react";
import {useEffect, useState} from "react";
import useAxios from "../../../utils/useAxios";
import {ListGroup, Offcanvas} from "react-bootstrap";
import {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import Settings from "../Settings/Settings";
import logo from '../../../assets/imges/logoNecFooter.png'
import "./Profile.css"
import "../../Navigation/NavigationLeft/NavigationLeft.css"


const Profile = () => {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [res, setRes] = useState([]);

    const [loading, setLoading] = useState(true)
    const api = useAxios();
    const {person, setPerson} = useContext(AuthContext);
    const author = localStorage.getItem("author")
    // console.log(author)
// console.log(person)


    const fetchData = async () => {
        // console.log(masClients)
        try {

            let dataUser = []
            if (person) {
                const response = await api.get(`/v1/profiluser/${person}/`);
                let data = await response.data
                dataUser.push(data)
                setRes(dataUser);
                localStorage.setItem("title", data.title)
                localStorage.setItem("costHour", data.cost_hour)
                localStorage.setItem("inn", data.counterparty_inn)
                localStorage.setItem("mail", data.email_address)


            }
        } catch {
            setRes("Something went wrong");
        }
        setLoading(false)
    };
    // console.log(res)
    useEffect(() => {

        fetchData();
    }, [person]);

    const Email = () => {
        for (let i = 0; i < res.length; i++) {
            if (res[i].email_address != "пусто") {
                return (
                    <ListGroup.Item
                        style={{fontWeight: "500", border: "none"}}>Email: {res[i].email_address}</ListGroup.Item>
                )
            } else {
                return null
            }
        }
    }
    const Holding = () => {
        for (let i = 0; i < res.length; i++) {
            if (res[i].holding != "пусто") {
                return (
                    <ListGroup.Item style={{fontWeight: "500",}}>Холдинг: {res[i].holding}</ListGroup.Item>
                )
            } else {
                return null
            }
        }
    }
    const TypeMany = () => {
        for (let i = 0; i < res.length; i++) {
            if (res[i].type_payment != "пусто") {
                return (
                    <ListGroup.Item style={{fontWeight: "500",}}>Тип оплаты: {res[i].type_payment}</ListGroup.Item>
                )
            } else {
                return null
            }
        }
    }


    // console.log(res)
    const taskListRender = res.map(userId => <ListGroup className="list-group-flush" key={userId.id}>
        <Settings/>

        <ListGroup.Item style={{fontWeight: "700"}}>
        </ListGroup.Item>
        <ListGroup.Item style={{fontWeight: "700", border: "none"}}>
            {userId.title}
        </ListGroup.Item>
        <Email/>
        {/*<ListGroup.Item style={{fontWeight: "500"}}>Email: {userId.email_address}</ListGroup.Item>*/}
        <ListGroup.Item style={{fontWeight: "500", border: "none"}}>ИНН: {userId.counterparty_inn}</ListGroup.Item>
        <ListGroup.Item style={{fontWeight: "500", border: "none"}}>Адрес: {userId.client_address}</ListGroup.Item>
        <hr/>
        <TypeMany></TypeMany>
        <Holding/>
        <ListGroup.Item style={{fontWeight: "500", border: "none"}}>Стоимость
            часа: {userId.cost_hour} ₽</ListGroup.Item>
        <ListGroup.Item style={{fontWeight: "500", border: "none"}}>Форма
            работы: {userId.form_clients_work}</ListGroup.Item>
        <ListGroup.Item style={{
            fontWeight: "500",
            border: "none",
            backgroundColor: "gold",
            borderRadius: "8px",
            width: "200px",
            marginLeft: "10px"
        }}>Баланс: {userId.balance} ₽</ListGroup.Item>

    </ListGroup>)

    return (
        <>
            <a onClick={handleShow}>
                ПРОФИЛЬ
            </a>

            <Offcanvas style={{border: "none"}} show={show} onHide={handleClose}>

                <Offcanvas.Header closeButton style={{backgroundColor: "#0086fd"}}>
                    <Offcanvas.Title>

                        <img className={"logoProfile"} src={logo}/>
                    </Offcanvas.Title>


                </Offcanvas.Header>
                <Offcanvas.Body>


                    {/*<img style={{width: "300px", marginLeft: "150px", paddingTop: "5px"}} src={logo}/>*/}
                    {/*<h1 style={{color: "#ffffff"}}>ПРОФИЛЬ</h1>*/}

                    {taskListRender}


                </Offcanvas.Body>

            </Offcanvas>
        </>
    )
}

export default Profile