import React from "react";
import "../components/Intro/Style/Auth.css"
import LeftColumn from "../components/Navigation/NavigationLeft/NavigationLeft";
import DocTab from "../components/Documents/DocBody/DocBody";
import UpScroll from "../components/upScroll/UpScroll";

function Documents() {

    return (
        <>
            <LeftColumn/>
            <UpScroll/>
            <div className={"tabelContent"}>
             <DocTab/>
            </div>
        </>
    );
}

export default Documents;
