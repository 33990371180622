import React, {useContext, useEffect} from "react";
import {utils, writeFile} from 'xlsx';
import AuthContext from "../../../context/AuthContext";
import {Button} from "react-bootstrap";
import "../NewTask/newTask.css"



const ExeleTasks = (props) => {
    const {resExel} = useContext(AuthContext)
    const {res} = useContext(AuthContext);
    const {taskDateStart} = useContext(AuthContext)
    const {fetchDataTasksExel} = useContext(AuthContext);
    const {taskDateFinish} = useContext(AuthContext);
    /* Callback invoked when the button is clicked */
    const xport = React.useCallback(async () => {
        /* Create worksheet from HTML DOM TABLE */
        const table = document.getElementById("Table2XLSX");
        const wb = utils.table_to_book(table);

        /* Export to file (start a download) */
        writeFile(wb, "SheetJSTable.xlsx");
    });

    useEffect(() => {
        fetchDataTasksExel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [res]);
   const name = localStorage.getItem("title")



    function ABBA(s) {
        const options = {
            // era: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            // weekday: 'long',
            timezone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        // res[i].date
        let inp = new Date(s)
        let out = inp.toLocaleString("ru", options)
        out = out.substring(0, out.indexOf(','));

        return out;
    }
    function ABBS(s) {
       let a = ""
        if (s == "пусто") {
            return a;
        }  else {return s}
    }

    //


    return (
        <>

            <Button  className={"ButtonNewTask"} onClick={xport}><b>Сформировать отчет</b></Button>

            <table style={{display: "none"}} id="Table2XLSX">

                <tr>
                    <td>Период</td>
                    <td colSpan="3" >
                        {taskDateStart} - {taskDateFinish}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">Контрагент</td>
                    <td colSpan="3">{name + ""}</td>
                </tr>
                <tr>

                </tr>
                <tr>
                    <th>Номер</th>
                    <th colSpan="3">Статус</th>
                    <th colSpan="5">Формулировка задачи</th>
                    <th colSpan="2">Дата постановки</th>
                    <th colSpan="2">Срок выполнения</th>
                    <th colSpan="4">Исполнитель</th>

                    <th colSpan="2">Трудозатраты</th>
                </tr>
                <tbody>
                { resExel.map(taskId =>
                    <tr>
                    <td >{taskId.numberTask}</td>
                    <td colSpan="3">{taskId.status}</td>
                    <td colSpan="5">{taskId.formulation_task}</td>
                    <td colSpan="2">{ABBA(taskId.date)}</td>
                    <td colSpan="2">{ABBA(taskId.due_date)}</td>
                        <td colSpan="4">{ABBS(taskId.executor)}</td>

                        <td colSpan="2">{ABBS(taskId.cost_of_work)}</td>
                    </tr>
                    )}
                </tbody>

            </table>
        </>
    )
}

export default ExeleTasks