import React from "react";
import "../components/Intro/Style/Auth.css"
import Presentation from "../../src/assets/imges/imgHome3.svg"
import "../components/Reports/Report.css"
import GneralSettlements from "../components/Reports/GneralSettlements";
import ReportsDate from "../components/Reports/ReportsDate";
import LeftColumn from "../components/Navigation/NavigationLeft/NavigationLeft";
function Reports() {

    return (
<>
    <LeftColumn/>

    <div className={"tabelContent"}>
        <div style={{marginLeft: "5px", marginBottom: "20px"}}><ReportsDate/></div>
        <GneralSettlements/>
        <img className={"imgReport"} src={Presentation}/>
    </div>

</>
    );
}

export default Reports;
