import ProfileSelection from "../components/ProfileBody/ProfileSelection/ProfileSelection";
import AuthContext from "../context/AuthContext";
import {useContext} from "react";
import StartNoAuth from "../components/Intro/StartNoAuth/StartNoAuth";

const Start = () => {
  const {user} = useContext(AuthContext);

  return (
      <>
        {user ? (
            <>
              <div style={{width: "1000px"}}>
                <ProfileSelection/>
              </div>
            </>
        ) : (
            <>
              <StartNoAuth/>
            </>

        )
        }
      </>
  );
};

export default Start;
