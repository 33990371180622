
import TaskList from "../components/TaskList/TaskList/TaskList";
import React from "react";
import LeftColumn from "../components/Navigation/NavigationLeft/NavigationLeft";
import UpScroll from "../components/upScroll/UpScroll";

function ProtectedPage() {

  return(
    <>
        <LeftColumn/>
        <UpScroll/>
        <section>
        <TaskList/>
        </section>
    </>
  );
}

export default ProtectedPage;
