import React, {useContext} from "react";
import DateDrop from "../../TaskList/DateDrop/DateDrop";
import "../Style/doc.css"
import {ABBA} from "../../Helpers/Helpers";
import Check from "../Cek/Check";
import AuthContext from "../../../context/AuthContext";

const RTU = (props) => {
    const {setSomePopupDataDocs, resDataDocs} = useContext(AuthContext);

    let batRtu = []
    for (let i = 0; i < resDataDocs.RTU.length; i++){
        if (resDataDocs.RTU[i].nomerdocschet === props.id){
            batRtu.push(resDataDocs.RTU[i])
        }
    }

    const typedoc = "rtu"


    const someCallback = (docid) => {
        setSomePopupDataDocs({
            ...docid,
            typedoc
        });
    };

    const rtuListRender = batRtu.map(docid =>
        <div style={{display: "flex"}}>
           <Check typeSc={"rtu"} doc={docid}/>
            <div className={"shetsActiv"} style={{cursor: "pointer"}} onClick={() => someCallback(docid)}>{docid.namedoc} {docid.nomerdoc} от {ABBA(docid.datedoc)} на сумму: {docid.summadoc} руб.</div>
        </div>
    )

    return (
        <>
            {rtuListRender}
        </>
    );

}
export default RTU