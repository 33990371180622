import {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import React from "react";
import "./NavigationTop.css"
import {Nav} from "react-bootstrap";
import {Navbar} from "react-bootstrap";
import logo from "../../../assets/imges/logoNec.png"
import Profile from "../../ProfileBody/Profile/Profile";

const NaviBlock = () => {
    const {user, logoutUser} = useContext(AuthContext);

    return (
        <div className={"header"}>
            <Navbar className={"navBar"} variant="light">

                <Nav className="me-auto">


                    {user ? (
                        <>
                            <Nav>
                                <Navbar.Brand href="/home" className={"navbarBrand"}><img className={"navbarBrandLogo"}
                                                                                          src={logo}/></Navbar.Brand>
                                <Nav.Link href="/protected" className={"navLink"}>ЗАДАЧИ</Nav.Link>

                                <Nav.Link className={"navLink"}><Profile/></Nav.Link>
                                
                                <Nav.Link href="/reports" className={"navLink"}>ОТЧЕТЫ</Nav.Link>
                                <Nav.Link href="/documents" className={"navLink"}>ДОКУМЕНТЫ</Nav.Link>
                                <Nav.Link href="/manual" className={"navLink"}>ПОМОЩЬ</Nav.Link>

                                <Nav.Link className={"navLinkLogout"} onClick={logoutUser}>ВЫЙТИ</Nav.Link>

                            </Nav>
                        </>
                    ) : (
                        <>
                            <Nav>
                                <Navbar.Brand href="/" className={"navbarBrand"}><img className={"navbarBrandLogo"}
                                                                                      src={logo}/></Navbar.Brand>
                                <Nav.Link href="/login" className={"navLink"}>ВОЙТИ</Nav.Link>
                                <Nav.Link href="/register" className={"navLink"}>РЕГИСТРАЦИЯ</Nav.Link>
                                <Nav.Link href="/home" className={"navLink"}>ИНФОРМАЦИЯ</Nav.Link>
                            </Nav>
                        </>
                    )}
                </Nav>
            </Navbar>
        </div>
    );
}
export default NaviBlock