import React, {useContext, useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import "react-widgets/styles.css";
import AuthContext from "../../../context/AuthContext";

const PassModal = () => {

    const {passCompleted, setPassCompleted} = useContext(AuthContext);
    // const passCompletedOpen = () => setPassCompleted(true);

    const taskCompletedClose = () => setPassCompleted(false);

    // passCompletedOpen()


    return (
        <>
            <Modal show={passCompleted} onHide={taskCompletedClose} animation={true}>
                <Modal.Header className={"TaskComp"} closeButton>
                </Modal.Header>

                <Modal.Body className={"TaskComp"}>
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                    <h1 className={"h1TaskComp"}>Ожидайте пароль на телефон</h1></Modal.Body>

                <Modal.Footer className={"TaskComp"}>
                    <Button onClick={taskCompletedClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default PassModal