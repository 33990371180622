import {createContext, useState, useEffect} from "react";
import jwt_decode from "jwt-decode";
import {useHistory, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({children}) => {
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );

    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? jwt_decode(localStorage.getItem("authTokens"))
            : null
    );

    // Используйте useLocation, чтобы получить объект location


    const [loading, setLoading] = useState(true);

    const history = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (queryParams.get("ClientId") && queryParams.get("TaskID")) {
            localStorage.setItem("clientId", queryParams.get("ClientId"))
            localStorage.setItem("taskID", queryParams.get("TaskID"))
        }
    }, []);

    const loginUser = async (username, password) => {
        const response = await fetch("https://necsoft.online/api/token/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                password
            })
        });
        const data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwt_decode(data.access));

            localStorage.setItem("authTokens", JSON.stringify(data))
            personsData()
            history("");


            setAlertAuth(false)
        } else {
            setAlertAuth(true)
            // alert("Что-то  не так");
        }
    };

    const reversUser = async (username, author) => {
        const response = await fetch("https://necsoft.online/jangoapi/v1/register/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },

            body: JSON.stringify({
                "passuser": username,
                "author": author,
            })
        });
        if (response.status === 201) {
            history("/login");
        } else {
            alert("Что-то пошло не так");
        }
    };

    const registerUser = async (username, author) => {
        const response = await fetch("https://necsoft.online/jangoapi/v1/register/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "passuser": username,
                "author": author,
            })
        });
        if (response.status === 201) {

            history("/login");
        } else {
            alert("Что-то пошло не так");
        }
    };

    const logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem("authTokens");
        localStorage.clear();
        history("");
    };


    const baseURL = "https://necsoft.online/jangoapi";
    const userClients = () => {
        const axiosInstance = axios.create({
            baseURL,
            headers: {Authorization: `Bearer ${authTokens?.access}`}
        });

        axiosInstance.interceptors.request.use(async req => {
            const user = jwt_decode(authTokens.access);
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
            if (!isExpired) {
                return req
            } else {
                logoutUser()
            }
            ;
            const response = await axios.post(`${baseURL}/token/refresh/`, {
                refresh: authTokens.refresh
            });


            localStorage.setItem("authTokens", JSON.stringify(response.data));

            setAuthTokens(response.data);
            setUser(jwt_decode(response.data.access));

            req.headers.Authorization = `Bearer ${response.data.access}`;
            return req;
        });

        return axiosInstance;
    };

    // const [author, setAuthor] = useState(null);
    const [persons, setPersons] = useState(null);
    const [person, setPerson] = useState(() =>
        localStorage.getItem("person")
            ? localStorage.getItem("person")
            : null
    );
    const [task, setTask] = useState("all");
    const [typeRequest, setTypeRequest] = useState("Все");
    const [typeWork, setTypeWork] = useState("Все");
    const [taskDateStart, setTaskDateStart] = useState("");
    const [taskDateFinish, setTaskDateFinish] = useState("");
    const [activityChanged, setActivityChanged] = useState(false);

    const personsData = () => {
        const api = userClients()
        api.get(`v1/user/`)
            .then(res => {
                    // setAuthor(res.data.user.author);
                    setPersons(res.data.user.refClient);
                    let statusUser = res.data.user.status
                    if (statusUser == 400) {
                        alert("Вам запрещен вход в Личный кабинет NecSoft")
                        logoutUser()
                    }
                    let author = res.data.user.author
                    let phone = res.data.user.username
                    localStorage.setItem("phone", phone)
                    localStorage.setItem("author", author)
                }
            )
    }
    const author = localStorage.getItem("author")

    const [res, setRes] = useState([]);

    const [somePopupData, setSomePopupData] = useState(null);


    const [alertAuth, setAlertAuth] = useState(false);
    const [count, setCount] = useState(0);
    const api = userClients();
    const [searchInput, setSearchInput] = useState("");
    const [checkWork, setCheckWork] = useState(false);


    const fetchDataTasks = async (offset = 0) => {
        try {
            const response = await api.get(`/v1/tasks/${task}/?date_after=${taskDateStart}&date_before=${taskDateFinish}&offset=${offset}&refUsers=${person}&search=${searchInput}&mailTypeRequest=${typeRequest === "Все" ? "" : typeRequest}&mailTypeWork=${typeWork === "Все" ? "" : typeWork}`);
            let data = await response.data.results
            let count = await response.data.count
            setCount(count)
            setRes(data);
        } catch {
            setRes("Something went wrong");
        }
    };
    const [resExel, setResExel] = useState([]);

    const fetchDataTasksExel = async (offset = 0) => {
        try {
            const response = await api.get(`/v1/tasks/${task}/?date_after=${taskDateStart}&date_before=${taskDateFinish}&offset=${offset}&refUsers=${person}&search=${searchInput}&limit=10000`);
            let data = await response.data.results
            setResExel(data);
        } catch {
            setResExel("Something went wrong");
        }
    };


    const [checkObj, setCheckObj] = useState({});
    const inn = localStorage.getItem("inn")

    const [resDataDocs, setResDataDocs] = useState(null);
    const fetchDataDocs = async () => {
        api.post("/v1/doc/", {
            "type": "0",
            "data": {
                "inn": inn,
                "uidclient": person,
                "sd": taskDateStart,
                "fd": taskDateFinish,
            }
        }).then((response) => {
            let data = response.data;
            setResDataDocs(data);

            setCheckObj([
                ...data.Akty,
                ...data.RTU,
                ...data.schet,
            ].reduce(function (accumulator, item, index, array) {
                accumulator[item.uiddoc] = false;
                return accumulator;
            }, {}));
        });
    };
    const [send, setSend] = useState(null);
    const [requestСompleted, setRequestСompleted] = useState(false);
    const requestСompletedOpen = () => setRequestСompleted(true);
    const postDataDocs = async (props) => {
        api.post("/v1/doc/", {
            "type": "1",
            "data": send,
            "mail": props
        }).then((response) => {
            requestСompletedOpen()
        })
    }

    const [dataSchet, setDataSchet] = useState(null)

    const [somePopupDataDocs, setSomePopupDataDocs] = useState(null)

    const [cekCompleted, setCekCompleted] = useState(false);
    const taskCompletedOpen = () => setCekCompleted(true);

    const [passCompleted, setPassCompleted] = useState(false);
    const passCompletedOpen = () => setPassCompleted(true);

    const [openString, setOpenString] = useState(true)


    const [checkAll, setChcekAll] = useState(false)


// Теперь у вас есть значение параметра, которое вы можете использовать


    const contextData = {
        taskCompletedOpen,
        requestСompletedOpen,
        requestСompleted, setRequestСompleted,
        send, setSend,

        checkObj, setCheckObj,
        checkAll, setChcekAll,
        openString, setOpenString,
        passCompleted, setPassCompleted,
        cekCompleted, setCekCompleted,
        dataSchet, setDataSchet,
        somePopupDataDocs, setSomePopupDataDocs,
        resDataDocs, setResDataDocs,
        checkWork, setCheckWork,
        setActivityChanged, activityChanged,
        resExel,
        searchInput, setSearchInput,
        count, setCount,
        res, setRes,
        taskDateStart, setTaskDateStart,
        taskDateFinish, setTaskDateFinish,
        alertAuth, setAlertAuth,
        author,
        persons,
        person, setPerson,
        somePopupData, setSomePopupData,
        task, setTask,
        typeRequest, setTypeRequest,
        typeWork, setTypeWork,
        user, setUser,
        authTokens, setAuthTokens,
        passCompletedOpen,
        postDataDocs,
        fetchDataDocs,
        fetchDataTasks,
        reversUser,
        personsData,
        registerUser,
        loginUser,
        logoutUser,
        fetchDataTasksExel,
    };


    useEffect(() => {
        if (authTokens) {
            setUser(jwt_decode(authTokens.access));
            personsData()

        }
        setLoading(false);
    }, [authTokens, loading]);

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};