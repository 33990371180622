import React from "react";

import "./loader.css"
import Presentation from "../../assets/imges/logoNecFooter.png";


const Loader = (props) => {
    if (props.isLoading === true) {
        return (
            <>
                <div className={"bodyLoader"} style={{}}>
                    <div className="coin">
                        <div className="side tail"><img style={{width: "200px"}} src={Presentation}/></div>
                        <div className="side head"><img style={{width: "200px"}} src={Presentation}/> </div>
                        <div className="edge"></div>
                    </div>
                </div>
            </>
        )
    } else return null

}

export default Loader