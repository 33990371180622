
import React, {useEffect, useState} from "react";
import HomeBody from "../components/HomeBody/HomeBody";
import useAxios from "../utils/useAxios";
import LeftColumn from "../components/Navigation/NavigationLeft/NavigationLeft";

const Home = () => {

    // const [res, setRes] = useState([]);
    // const api = useAxios();
    //
    // const fetchDataTasks = async () => {
    //     try {
    //         const response = await api.get(`/v1/file/`);
    //         let data = await response.data.results
    //         let count = await response.data.count
    //         setRes(data);
    //     } catch {
    //         setRes("Something went wrong");
    //     }
    // };
    // // fetchDataTasks()
    // console.log(res[0].file)
    // let k = res[0].file
    // console.log(k)
    // useEffect(() => {
    //
    //     fetchDataTasks();
    // }, []);
    return (
        <>
            <LeftColumn/>


            <HomeBody/>
            {/*<img style={{position: "absolute"}} src="http://127.0.0.1:8000/media/filesto/png-clipart-cap-hat-headgear-cap-hat-photography_Ehc11ge.png"/>*/}
        </>
    )
}
export default Home