import React, {useEffect, useState} from "react";
import useAxios from "../../../utils/useAxios";
import {Accordion, Button, Card, Form, ListGroup, Modal} from "react-bootstrap";
import "./ExampleDataForm.css"
function ExampleData(props) {

    const [lgShow, setLgShow] = useState(false);
    const [res, setRes] = useState([]);
    const [loading, setLoading] = useState(true)
    const api = useAxios();
    const fetchData = async () => {
        try {
            const response = await api.get(`/v1/comments/?refTask=${props.id}`);
            let data = await response.data.results
            setRes(data);
        } catch {
            setRes("Something went wrong");
        }
        setLoading(false)
    };
    useEffect(() => {
        fetchData();
    }, [props.id]);

    const commentListRender = res.map(commentId =>
        <Accordion  defaultActiveKey=" " flush>
            <Accordion.Item eventKey={commentId.id}>
                <Accordion.Header key={commentId.id}>Комментарий № {commentId.numberLine}</Accordion.Header>
                <Accordion.Body>
                    <ListGroup variant="flush" key={commentId.id}>
                        <Form.Group className="mb-10" controlId="exampleForm.ControlTextarea1"
                                    style={{marginTop: "20px"}}>
                            <Form.Control as="textarea" readOnly rows={7} value={commentId.commentContent}/>
                        </Form.Group>
                        <div>
                            <div className={"pArg2"}>Автор:</div>
                            {commentId.author}</div>
                        <div>
                            <div className={"pArg2"}>Дата:</div>
                            {commentId.dateTime}</div>

                        {/*<ListGroup.Item><Form.Control plaintext readOnly defaultValue={commentId.dateTime}/></ListGroup.Item>*/}

                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
    return (
        <>
            {commentListRender}
        </>);
}

export default ExampleData