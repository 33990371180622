import React from "react";
import "./index.css";

import 'bootstrap/dist/css/bootstrap.min.css';

import Footer from "./components/Navigation/Footer/Footer";
import NaviBlock from "./components/Navigation/NavigationTop/NavigationTop";
import {
    BrowserRouter,
    BrowserRouter as Router,
    Route,
    Routes,
    Switch,
    useLocation,
    useSearchParams
} from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import {AuthProvider} from "./context/AuthContext";
import Login from "./views/LoginPage";
import Register from "./views/RegisterPage";
import ProtectedPage from "./views/ProtectedPage";
import "./App.css"
import startPage from "./views/IntroPage";
import homePage from "./views/HomePage";
import "../src/components/Reports/Report.css"
import SomeModal from "./components/TaskList/SomeModal/SomeModal";
import ReversUserPage from "./views/ReversPage";
import ReportsPage from "./views/ReportsPage";
import ReportResult from "./components/Reports/ReportResult";
import Documents from "./views/Documents";
import SomeDoc from "./components/Documents/SomeDoc/SomeDoc";
import CekModal from "./components/Documents/CekPostModal/CekPostModal";
import PassModal from "./components/Intro/PassModal/PassModal";
import ManualPage from "./views/ManualPage";
import CekPostModalStatic from "./components/Documents/CekPostModal/CekPostModalStatic";
import Home from "./views/HomePage";
import Start from "./views/IntroPage";

function App() {

      // console.log(searchParams)
    function HeaderView() {
        const location = useLocation();
        if (location.pathname !== "/report/") {
            return (<Footer/>)
        } else return null
    }

    return (
<BrowserRouter>
            <div className={"App"}>

                <AuthProvider>
                    <SomeModal/>
                    <SomeDoc/>
                    <CekPostModalStatic/>
                    <PassModal/>
                    <CekModal/>
                    <NaviBlock/>
                    <div className={"contet"}>
                        <Routes  className="contet">

                            <Route path='/login' element={<Login />} />
                            <Route path='/home' element={<Home />} />
                            <Route path='/' element={<Start />} />
                            <Route path='/revers' element={<ReversUserPage />} />
                            <Route path='/register' element={<Register />} />


                            <Route path='/protected'
                                element={
                                    <PrivateRoute>
                                        <ProtectedPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route path='/reports'
                                element={
                                    <PrivateRoute>
                                        <ReportsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route path='/report'
                                element={
                                    <PrivateRoute>
                                        <ReportResult />
                                    </PrivateRoute>
                                }
                            />
                            <Route path='/documents'
                                element={
                                    <PrivateRoute>
                                        <Documents />
                                    </PrivateRoute>
                                }
                            />
                            <Route path='/manual'
                                element={
                                    <PrivateRoute>
                                        <ManualPage />
                                    </PrivateRoute>
                                }
                            />

                        </Routes>
                    </div>
                </AuthProvider>
                <HeaderView/>
            </div>

        </BrowserRouter>

    );
}

export default App;
