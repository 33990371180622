import {Button, ButtonGroup, Dropdown, DropdownButton, Form} from "react-bootstrap";
import React, {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import "../TaskList/TaskList.css"

const TaskFilterPost = () => {
        const {typeRequest, setTypeRequest, typeWork, setTypeWork, person} = useContext(AuthContext);

 // Тип запроса
    const typeRequestObj = [
        {key : 0, status: "Все"},
        {key : 1, status: "ЗНИ"},
        {key : 2, status: "ЗНО"},
        {key : 3, status: "Инцидент"},
        {key : 4, status: "Обновление АС"},
        {key : 5, status: "ЗНИ 1-2 типа"},
        {key : 6, status: "Раздел основного ТЗ"},
     ]
 // Тип работ
     const typeWorkObj = [
         {key : 0, status: "Все"},
         {key : 1, status: "Управление торговлей (АСКУ)"},
         {key : 2, status: "Зарплата и управление персоналом (АСЗУП)"},
         {key : 3, status: "Бухгалтерия предприятия (АСБНУ)"},
         {key : 4, status: "Система электронного документооборота (СЭД)"},
         {key : 5, status: "Управление производственным предприятием (УПП)"},
         {key : 6, status: "ERP управление предприятием (УП)"},
         {key : 7, status: "Прочая система на платформе 8.1 и выше"},
         {key : 8, status: "Прочая система на платформе 7.7"},
         {key : 9, status: "Управление автотранспортом (УАТ)"},
         {key : 10, status: "Обучение/подготовка к экзамену"},
     ]


       const typeRequestStatusRender = typeRequestObj.map(typeRequestData =>
               <Dropdown.Item onClick={() => setTypeRequest(typeRequestData.status)}>{typeRequestData.status}</Dropdown.Item>
    )

    const typeWorkStatusRender = typeWorkObj.map(typeWorkData =>
               <Dropdown.Item onClick={() => setTypeWork(typeWorkData.status)}>{typeWorkData.status}</Dropdown.Item>
    )

                return (
                    <div style={{marginLeft: "1vw", display: "flex"}}>
                        <Dropdown>
                            <Dropdown.Toggle className={"DropButtonStatus"} id="dropdown-basic">
                                Тип запроса: {typeRequest}

                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {typeRequestStatusRender}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown style={{marginLeft: "1vw"}}>
                            <Dropdown.Toggle className={"DropButtonStatus"} id="dropdown-basic">
                                Тип работ: {typeWork}

                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {typeWorkStatusRender}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )

}

export default TaskFilterPost