import React, {useContext, useState} from "react";
import "./TaskList.css"
import {Button, Table, Form} from "react-bootstrap";
import {useEffect} from "react";
import Pagination from "../Pagination/Pagination";
import StatusFilter from "../Drop/Drop";
import Search from "../Search/Search";
import ExeleTasks from "../ExelTasks/ExeleTasks";
import NewTask from "../NewTask/NewTask";
import AuthContext from "../../../context/AuthContext";
import DateDrop from "../DateDrop/DateDrop";
import {ABBA} from "../../Helpers/Helpers";
import ReportsDate from "../../Reports/ReportsDate";
import useAxios from "../../../utils/useAxios";
import TaskFilterPost from "../TaskFilterPost/TaskFilterPost";

const TaskList = () => {

    const api = useAxios();

    const {
        task,
        typeRequest,
        typeWork,
        setSomePopupData,
        person,
        fetchDataTasks,
        taskDateStart,
        taskDateFinish,
        res,
        count,
        searchInput
    } = useContext(AuthContext);
    const limit = 50


    useEffect(() => {
        fetchDataTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [person, task, taskDateStart, taskDateFinish, searchInput, typeRequest, typeWork]);


    const someCallback = (taskId) => {
        setSomePopupData({
            ...taskId,
        });
    };


    const [allTask, setAllTask] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/v1/tasks/all/?refUsers=${localStorage.getItem("clientId")}&id=${localStorage.getItem("taskID")}`);
                setAllTask(response.data.results)
            } catch (error) {
                setAllTask([]);
            }
        };
        if (localStorage.getItem("taskID") && localStorage.getItem("clientId") === person) {
            fetchData()
        }
    }, []);

    useEffect(() => {
        for (let taskId of allTask) {
            if (taskId.id === localStorage.getItem("taskID")) {
                someCallback(taskId);
            }
        }
    }, [allTask]);


    const taskListRender = res.map(taskId => {

            return (
                <>
                    <tbody>
                    <tr className={"allTabs"} key={taskId.id}>
                        <td style={{width: "13%"}} className={"numberTask"}
                            onClick={() => someCallback(taskId)}>{taskId.numberTask}<br/>{ABBA(taskId.date)}</td>
                        <td style={{width: "30%"}} className={"numberTask"}
                            onClick={() => someCallback(taskId)}>{taskId.author}<br/>{taskId.executor}
                        </td>

                        <td rowSpan={"2"} style={{width: "40%"}} onClick={() => someCallback(taskId)}
                            title={taskId.formulation_task}
                            className={"formulation_task"}>

                            <Form.Control
                                as="textarea"
                                disabled
                                className={"TextField"}
                                defaultValue={taskId.formulation_task}
                            />
                        </td>
                        <td rowSpan={"2"} className={"status"} onClick={() => someCallback(taskId)}>{taskId.status}</td>
                    </tr>
                    </tbody>
                </>
            )
        }
    )

    return (
        <>
            <div className={"tabelContent"}>
                <div style={{margin: "10px", display: "flex"}}>
                    <ReportsDate/>
                    {person == "61ade4bc-9464-11e7-aafd-40167eaae633" ?
                    <TaskFilterPost/> : null}
                </div>

                <Button disabled={person == "61ade4bc-9464-11e7-aafd-40167eaae633"} className={"ButtonNewTask"}><NewTask/></Button>
                <Search/>
                <ExeleTasks/>


                <Table bordered hover>
                    <thead>
                    <tr className={"headerTabel"}>
                        <th className={"NumHeader"}>Номер</th>
                        <th>
                            Автор
                        </th>
                        <th rowSpan={"2"}>Формулировка</th>
                        <th rowSpan={"2"} colspan="2">
                            <StatusFilter/>
                        </th>
                    </tr>
                    <tr className={"headerTabel"}>
                        <th>
                            Дата
                            {/*<DateDrop/>*/}
                        </th>
                        <th>
                            Исполнитель
                        </th>
                    </tr>

                    </thead>
                    {taskListRender}
                </Table>
                <Pagination pageCount={count / limit} onPageChange={page => fetchDataTasks(page * limit)}/>
            </div>
        </>
    );

}
export default TaskList