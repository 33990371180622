import React, {useContext, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import AuthContext from "../../../context/AuthContext";

const Check = (props) => {
    const {checkObj, setCheckObj} = useContext(AuthContext);


    const [itsCheck, setItsCheck] = useState(false);
    // props.statusCheck

    useEffect(() => {
        setItsCheck(checkObj[props.doc.uiddoc]);
    }, [checkObj[props.doc.uiddoc]]);



    function handleChange() {
        setCheckObj({
            ...checkObj,
            [props.doc.uiddoc]: !itsCheck,
        });
    }


    return (
        <>
            <Form.Check checked={itsCheck} reverse={false} onChange={handleChange}/>
        </>
    )
}
export default Check