import React, {useContext, useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {useHistory, useNavigate} from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import useAxios from "../../../utils/useAxios";


function ProfileSelection() {

    const {persons} = useContext(AuthContext);
    const {person, setPerson} = useContext(AuthContext);

    const api = useAxios()
    const [res, setRes] = useState([]);


    function refPerson (persons) {
        let test = false
        for (let i = 0; persons.length > i; i++) {
            if (persons[i] === localStorage.getItem("clientId")) {
                test = true
            }
        }
        return test
    }

    // console.log(refPerson(persons))


    // console.log(res)

    useEffect(async () => {


        if(!persons) {
            return;
        }

         try {
            let list = []
            for (let i = 0; i < persons.length; i++) {
                const response = await api.get(`/v1/profiluser/${persons[i]}/`);
                let data = await response.data
                list.push(data)
            }
            setRes(list)
            setPerson(refPerson(persons) ? localStorage.getItem("clientId") : persons[0])

        } catch {
            // console.log("Ошибка");
        }
    }, [persons]);

    // const [person, setPerson] = useState(null)
    const [show, setShow] = useState(true);
    const history = useNavigate();


    const handleClose = () => {
        localStorage.setItem("person", person)

        setShow(false)

        history("/protected")

    }
    const handleShow = () => setShow(true);

    const clientsList = res.map(userId => (
        <option key={userId.id} value={userId.id}>{userId.title}</option>
    ));

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Выберите предприятие для работы</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Select  autoFocus
                                 value={person} onChange={e => setPerson(e.target.value)}
                                 aria-label="Default select example">
                        {clientsList}
                    </Form.Select></Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Выбрать
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProfileSelection