import {Button, Form, Modal} from "react-bootstrap";
import React, {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";

const ReportsDate = () => {
    const [show, setShow] = useState(false);
    const {taskDateStart, setTaskDateStart} = useContext(AuthContext);
    const {taskDateFinish, setTaskDateFinish} = useContext(AuthContext);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [inputDueDateStart, setInputDueDateStart] = useState(new Date())
    const [inputDueDateFinish, setInputDueDateFinish] = useState(new Date())
    const {setActivityChanged} = useContext(AuthContext);

    let dateCoint = ""

    if (taskDateStart && taskDateFinish) {
        dateCoint = "Изменить период C " + taskDateStart + " По " + taskDateFinish
    } else {dateCoint = "Указать период"}

    function ABBA(s) {
        const options = {
            // era: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            // weekday: 'long',
            timezone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        // res[i].date

        let inp = new Date(s)
        let out = inp.toLocaleString("ru", options)
        out = out.substring(0, out.indexOf(','));

        return out;
    }

    const handleCloseSave = () => {
        setShow(false);
        // console.log(ABBA(inputDueDateStart))
        setTaskDateStart(ABBA(inputDueDateStart))
        setTaskDateFinish(ABBA(inputDueDateFinish))
        if(inputDueDateFinish && inputDueDateStart) {
            setActivityChanged(true)
        }

    }
    return (
        <>
            <Button style={{border: "none"}} onClick={handleShow}>
                 {dateCoint} <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg" color="#ffffff">
                <path d="M8 16V8M12 16v-5M16 16v-3" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                <path d="M3 20.4V3.6a.6.6 0 01.6-.6h16.8a.6.6 0 01.6.6v16.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6z"
                      stroke="#ffffff" stroke-width="1.5"></path>
            </svg>
            </Button>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Выбор периода</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className={"FormDateInp"}>
                        <div className={"FormDateBody"}>
                            С
                            <div className={"FormDateInp"}>
                                <Form.Group className="mb-3" controlId="date">
                                    <Form.Control value={inputDueDateStart}
                                                  type="date"
                                                  onChange={(e) => setInputDueDateStart(e.target.value)}
                                    />
                                </Form.Group>
                            </div>

                            По
                            <div className={"FormDateInp"}>
                                <Form.Group className="mb-3" controlId="date">
                                    <Form.Control value={inputDueDateFinish}
                                                  type="date"
                                                  onChange={(e) => setInputDueDateFinish(e.target.value)}
                                    />
                                </Form.Group></div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={handleCloseSave}>
                        Применить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ReportsDate
