import {Button, ButtonGroup, Dropdown, DropdownButton, Form} from "react-bootstrap";
import React, {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import "../TaskList/TaskList.css"

const StatusFilter = () => {
        const {task, setTask} = useContext(AuthContext);
        const statusList = [
            {key : 0, url: "all", status: "Все"},
            {key : 1, url: "completed", status: "Выполнена"},
            {key : 2, url: "pogress", status: "Принята в работу"},
            {key : 3, url: "performance", status: "Постановка"},
            {key : 4, url: "performer", status: "Отправлено исполнителю"},
            {key : 5, url: "testing", status: "Отправлено на тестирование заказчику"},
            {key : 6, url: "testingcustome", status: "Протестировано заказчиком без замечаний"},
            {key : 7, url: "forrevision", status: "Протестировано заказчиком и возвращена на доработку"},
            {key : 8, url: "rejectedperformer", status: "Отклонена исполнителем"},
            {key : 9, url: "rejectedcustomer", status: "Отклонена заказчиком"},
            {key : 10, url: "postponed", status: "Отложена"},
            {key : 11, url: "todebt", status: "Отклонена исполнителем по причине задолженности"},
            {key : 12, url: "evaluation", status: "Оценка задачи"},
            {key : 13, url: "cancelled", status: "Аннулировано"},
            {key : 14, url: "onapproval", status: "На согласовании"}
        ]

  const Nupf = (k) => {
            for (let i = 0; i < statusList.length; i++) {
                if (statusList[i].status == k) {
                    setTask(statusList[i].url)
                }
            }
            return
   }
       const taskListStatusRender = statusList.map(taskStatus =>
               <Dropdown.Item onClick={() =>Nupf(taskStatus.status)}>{taskStatus.status}</Dropdown.Item>
    )
    return(
          <Dropdown>
      <Dropdown.Toggle className={"DropButtonStatus"} variant="none" id="dropdown-basic">
             Статус

      </Dropdown.Toggle>
              <Dropdown.Menu>
            {taskListStatusRender}
              </Dropdown.Menu>
          </Dropdown>
    )
}

export default StatusFilter