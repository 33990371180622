import React from "react";
import "../components/Intro/Style/Auth.css"
import Registration from "../components/Intro/Registration/Registration";

function Register() {

    return (
        <Registration/>
    );
}

export default Register;
