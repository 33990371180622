import * as React from 'react';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useEffect, useState} from "react";
import useAxios from "../../utils/useAxios";
import NewComment from "../TaskList/ExampleDataForm/newComment";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,

});


export default function FilePost(props) {
    const api = useAxios();
    const [allFiles, setAllFiles] = useState([])
    const fetchData = async () => {
        try {
            const response = await api.get(`/v1/file/?refTask=${props.id}`);
            setAllFiles(response.data.results)
        } catch (error) {
            setAllFiles([]);
        }
    };

    useEffect(() => {
        fetchData()
    }, []);

    const handleFileUpload = (event) => {
        const formData = new FormData();
        // formData.append('file', file);
        formData.append('refTask', props.id);
        formData.append('file', event.target.files[0]);
        formData.append('name', event.target.files[0].name);


        api.post('/v1/file/', formData)
            .then(response => {
                fetchData();
            })
            .catch(error => {
                console.error(error);
            });
    };

        const FileRender = () => {
        if (props.status === "Выполнена") {
            return null;
        } else if (props.status === "Аннулировано") {
            return null;
        } else {
            return (<Button style={{marginBottom: "1vh"}}  component="label" onChange={handleFileUpload} variant="contained"
                        startIcon={<CloudUploadIcon/>}>
                    Добавить файл
                    <VisuallyHiddenInput type="file"/>
                </Button>)
        }
    }
    return (
        <div>
            <div>
                {allFiles.map((file) => {
                    return (
                        <>
                            <Button target="_blank" href={file.file}>{file.name}</Button>
                        </>
                    )
                })}
            </div>
            <div>
                <FileRender/>
            </div>

        </div>
    );
}
