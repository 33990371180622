import React, {useEffect, useState} from "react";
import useAxios from "../../../utils/useAxios";
import {Accordion, Button, Card, Form, ListGroup, Modal} from "react-bootstrap";
import "./HistoryTask.css"

    function ABBA(s) {
        const options = {
            // era: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            // weekday: 'long',
            timezone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        // res[i].date
        let inp = new Date(s)
        let out = inp.toLocaleString("ru", options)
        out = out.substring(0, out.indexOf(','));

        return out;
    }

function HistoryTask(props) {
    const [res, setRes] = useState([]);
    const [loading, setLoading] = useState(true)
    const api = useAxios();
    const fetchData = async () => {
        try {
            const response = await api.get(`/v1/historyTasks/?numberTask=${props.numberTask}`);
            let data = await response.data.results
            setRes(data);
        } catch {
            setRes("Something went wrong");
        }
        setLoading(false)
    };
    useEffect(() => {
        fetchData();
    }, [props.id]);

    const commentListRender = res.map(historyElem =>
        <Accordion defaultActiveKey=" " flush>
            <Accordion.Item eventKey={historyElem.id}>
                <Accordion.Header key={historyElem.id}>{ABBA(historyElem.newDate)}</Accordion.Header>
                <Accordion.Body>
                    <ListGroup variant="flush" key={historyElem.id}>
                                                            <div style={{fontSize: "11pt"}}>
                                        <div>
                                            <div className={"pArg"}>Контрагент:</div>
                                            {historyElem.counterparty}</div>
                                        <div>
                                            <div className={"pArg"}>ИНН:</div>
                                            {historyElem.counterparty_inn}</div>
                                        <div>
                                            <div className={"pArg"}>Стоимость работы:</div>
                                            {historyElem.cost_of_work + " ₽"}</div>
                                        <div>
                                            <div className={"pArg"}>Статус:</div>
                                            <div className={"pArg3"}>
                                                {historyElem.status}
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"pArg"}>Исполнитель:</div>
                                            {historyElem.executor}
                                        </div>
                                        <div>
                                            <div className={"pArg"}>Дата постановки:</div>
                                            {ABBA(historyElem.date)}
                                        </div>
                                        <div>
                                            <div className={"pArg"}>Срок выполнения задачи:</div>
                                            {ABBA(historyElem.due_date)}
                                        </div>
                                        <div>
                                            <div className={"pArg"}>План-дата выполнения:</div>
                                            {ABBA(historyElem.due_date)}
                                        </div>

                                        <div>
                                            <div className={"pArg"}>Начало работы:</div>
                                            {ABBA(historyElem.get_started)}</div>
                                        <div>
                                            <div className={"pArg"}>Автор:</div>
                                            {historyElem.author}</div>
                                    </div>

                        <Form.Group className="mb-10" controlId="exampleForm.ControlTextarea1"
                                    style={{marginTop: "20px"}}>
                            <Form.Control as="textarea" readOnly rows={7} value={historyElem.formulation_task}/>
                        </Form.Group>
                        {/*<div>*/}
                        {/*    <div className={"pArg2"}>Автор:</div>*/}
                        {/*    {commentId.formulation_task}</div>*/}
                        {/*<div>*/}
                        {/*    <div className={"pArg2"}>Дата:</div>*/}
                        {/*    {commentId.dateTime}</div>*/}

                        {/*<ListGroup.Item><Form.Control plaintext readOnly defaultValue={commentId.dateTime}/></ListGroup.Item>*/}

                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
    return (
        <>
            <Accordion  style={{marginTop: "1vw"}} defaultActiveKey=" " flush>
                <Accordion.Item  >
                    <Accordion.Header >История</Accordion.Header>
                    <Accordion.Body >
                        {commentListRender}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>);
}

export default HistoryTask