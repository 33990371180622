import React from "react";
import {Button} from "react-bootstrap";
import "../Style/Intro.css"
import logo from '../../../assets/imges/logoNecFooter.png'
import {Link} from "react-router-dom";
import "../../HomeBody/HomeBody.css"
import Background from "../Background/Background";

function StartNoAuth() {

    return (

        <div className={"context"}>
            <img className={"logo"} src={logo}/>
            <h1 className={"context h1"}>Добро пожаловать в личный кабинет</h1>
            <Link to="/login">
                <Button type="button" style={{backgroundColor: "rgba(204,204,204,0)", color: "wheat"}}
                        className={"button"}>Начать работу</Button>
            </Link>
            <Background/>

        </div>


    );

}

export default StartNoAuth;