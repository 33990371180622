import "./NavigationLeft.css";
import {Nav} from "react-bootstrap";
import AuthContext from "../../../context/AuthContext";
import {useContext} from "react";
import Profile from "../../ProfileBody/Profile/Profile";
import React from "react";

const LeftColumn = () => {
    const {user, logoutUser} = useContext(AuthContext);

    return (
        <div>

            {user && (


                // <div className={'headerLeftBloc'}>
                <Nav defaultActiveKey="/home" className="flex-column leftColumNav">


                    <div style={{position: "fixed"}}>


                        {/*<Nav.Link className={"listGroup3"} ><NewTask/></Nav.Link>*/}

                        <Nav.Link className={"listGroup"}>
                            <Profile/></Nav.Link>
                        <Nav.Link className={"listGroup"} href="/protected">
                            ЗАДАЧИ </Nav.Link>
                        <Nav.Link className={"listGroup"} href="/documents">
                            ДОКУМЕНТЫ </Nav.Link>
                        <Nav.Link className={"listGroup"} href="/reports">
                            ОТЧЕТЫ
                        </Nav.Link>
                        <Nav.Link className={"listGroup"} href="/manual">
                            ПОМОЩЬ
                        </Nav.Link>
                        <Nav.Link onClick={logoutUser} className={"listGroup2"} eventKey="link-2">
                            <svg width="20" height="20" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 12H19M19 12L16 15M19 12L16 9" stroke="currentColor" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path
                                    d="M19 6V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V18"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            ВЫЙТИ </Nav.Link>

                    </div>
                </Nav>

                // </div>
            )
            }
        </div>
    )
};

export default LeftColumn;