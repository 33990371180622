import React, {useContext} from "react";
import {Button, Form} from "react-bootstrap";
import AuthContext from "../../../context/AuthContext";
const SelectAllButton = () => {
    const {checkAll, setChcekAll, checkObj, setCheckObj} = useContext(AuthContext);
    function change() {
        let checkStatus = !checkAll;
        let refObj = {};
        Object.keys(checkObj).forEach(function (item){
           refObj[item] = checkStatus;
        });
        setCheckObj(refObj);
        setChcekAll(checkStatus);
    }




    return (
        <>
            <Button className={"buttomString"} onClick={change}>
                Выбрать все ✅
            </Button>
        </>
    )
}
export default SelectAllButton