import AuthContext from "../../context/AuthContext";
import {useContext, useEffect, useState} from "react";
import useAxios from "../../utils/useAxios";
import {useLocation, useSearchParams} from "react-router-dom";
import Loader from "../loader/loader";

const ReportResult = () => {
    const {person, taskDateStart, taskDateFinish} = useContext(AuthContext);
    const [resData, setResData] = useState(null)
    const [resDataPost, setResDataPost] = useState(null)
    const api = useAxios();
    const {setSomePopupData} = useContext(AuthContext);
    const {task} = useContext(AuthContext);
    const {checkWork} = useContext(AuthContext);
    const [loading, setLoading] = useState(true)
    const customer = localStorage.getItem("title")

    const someCallback = (taskId) => {
        setSomePopupData({
            ...taskId,
        });
    };

    function empty(em) {
        if (em == "пусто") {
            return 0
        } else return em
    }

    function cunt(n) {
        const cost = parseInt(localStorage.getItem("costHour").replace(/\s+/g, ''), 10)
        if (n !== "пусто") {
            n = parseInt(n.replace(/\s+/g, ''), 10)
            let k = n / cost
            return Math.floor(k)
        } else return 0
    }

    function ABBA(s) {
        if (s == null) {
            return ""
        } else {
            const options = {
                // era: 'long',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                // weekday: 'long',
                timezone: 'UTC',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            };
            // res[i].date
            let inp = new Date(s)
            let out = inp.toLocaleString("ru", options)
            out = out.substring(0, out.indexOf(','));

            return out;
        }
    }

    const [searchParams] = useSearchParams();
    const parm = searchParams.get("param");
    if (!resData) {
        if (parm == "General") {
            let state = {
                "RequestType": "MutualSettlements",
                "idcl": person,
                "datestart": taskDateStart,
                "datefinish": taskDateFinish,
            }
            api.post("/v1/report/", state).then((response) => {
                setResData(response.data)
                setLoading(false)
            })
        } else if (parm === "Tasks") {
            api.get(`/v1/tasks/${task}/?date_after=${taskDateStart}&date_before=${taskDateFinish}&refUsers=${person}&limit=10000`).then((response) => {
                setResData(response.data.results)
                setLoading(false)
            });


        } else if (parm === "Post") {
            api.get(`/v1/tasks/?due_date_after=${taskDateStart}&due_date_before=${taskDateFinish}&refUsers=${person}&limit=10000`).then((response) => {
                setResData(response.data.results)
            });

            api.get(`/v1/tasks/completed/?closed_date_after=${taskDateStart}&closed_date_before=${taskDateFinish}&refUsers=${person}&limit=10000`).then((response) => {
                setResDataPost(response.data.results)
                setLoading(false)
            });

        } else if (parm === "Louvre" && checkWork) {
            api.get(`/v1/tasks/?date_after=${taskDateStart}&date_before=${taskDateFinish}&refUsers=${person}&limit=10000`).then((response) => {
                setResData(response.data.results)
                setLoading(false)
            });
        } else if (parm === "Louvre" && !checkWork) {
            api.get(`/v1/tasks/completed/?date_after=${taskDateStart}&date_before=${taskDateFinish}&refUsers=${person}&limit=10000`).then((response) => {
                setResData(response.data.results)
                setLoading(false)
            });
        }
    }
    const General = () => {

        if (parm == "General" && resData) {
            console.log(resData)
            const massAll = resData["massData"]
            const commentListRender = massAll.map(resData =>
                <tr>
                    <td style={{textAlign: "center"}}>
                        {resData.TypeOfOperation}
                    </td>
                    <td style={{width: "50%"}}>
                        {resData.FormulationTask}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {resData.StartingBalance}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {resData.Replenishment}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {resData.WriteDowns}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {resData.FinishingBalance}
                    </td>
                </tr>
            )
            return (
                <table className={"tableReport"}>

                    <tr>
                        <th rowSpan={2}>
                            Событие
                        </th>
                        <th rowSpan={2}>
                            Формулировка
                        </th>
                        <th>
                            Остаток на начало
                        </th>
                        <th>
                            Начислено
                        </th>
                        <th>
                            Списано
                        </th>
                        <th>
                            Остаток на конец
                        </th>
                    </tr>


                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        {resData["valueData"].StartingBalance}
                    </td>
                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        {resData["valueData"].Replenishment}

                    </td>
                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        {resData["valueData"].WriteDowns}
                    </td>
                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        {resData["valueData"].FinishingBalance}
                    </td>
                    {commentListRender}

                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        Итого
                    </td>
                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                    </td>
                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        {resData["valueData"].StartingBalance}
                    </td>
                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        {resData["valueData"].Replenishment}

                    </td>
                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        {resData["valueData"].WriteDowns}
                    </td>
                    <td style={{textAlign: "center", backgroundColor: "#00ff93"}}>
                        {resData["valueData"].FinishingBalance}
                    </td>

                </table>
            )
        } else return null
    }
    const TaskRep = () => {
        if (parm == "Tasks" && resData) {
            const massAll = resData
            let count = 0
            for (let i = 0; resData.length > i; i++) {

                if (resData[i].cost_of_work !== "пусто") {
                    let k = 0
                    k = parseInt(resData[i].cost_of_work.replace(/\s+/g, ''), 10)
                    count += k
                }
            }
            console.log(count)

            const commentListRender = massAll.map(resData =>
                <tr>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.numberTask}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.status}
                    </td>
                    <td style={{textAlign: "center", width: "15%"}} onClick={() => someCallback(resData)}>
                        {resData.counterparty}
                    </td>
                    <td style={{width: "30%"}} onClick={() => someCallback(resData)}>
                        {resData.formulation_task}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {ABBA(resData.date)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {ABBA(resData.due_date)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.executor}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {empty(resData.cost_of_work)}
                    </td>
                </tr>
            )

            console.log(massAll)
            return (
                <>
                    <h5 style={{textAlign: "center", marginTop: "20px"}}><b>Отчет по задачам</b></h5>

                    <table className={"tableReport"}>

                        <tr>
                            <th colSpan={2}>Период</th>
                            <th colSpan={6}>C {taskDateStart} По {taskDateFinish}</th>
                        </tr>


                        <tr>
                            <th rowSpan={2}>
                                Номер
                            </th>
                            <th rowSpan={2}>
                                Статус
                            </th>
                            <th rowSpan={2}>
                                Контрагент
                            </th>
                            <th rowSpan={2}>
                                Формулировка задачи
                            </th>
                            <th rowSpan={2}>
                                Дата постановки
                            </th>
                            <th rowSpan={2}>
                                Срок выполнения
                            </th>
                            <th>
                                Исполнитель
                            </th>
                            <th>
                                Трудозатраты
                            </th>
                        </tr>
                        <tr>


                            <th style={{backgroundColor: "#00ff93"}}>
                                Всего
                            </th>
                            <th style={{backgroundColor: "#00ff93"}}>
                                {count} ₽
                            </th>
                        </tr>
                        {commentListRender}
                    </table>
                </>)

        } else return null
    }
    const PostRep = () => {

        if (parm == "Post" && resData && resDataPost) {


            var targets = resData.filter(function (el) {
                return el.status == "Принята в работу"
                    || el.status == "Отправлено на тестирование заказчику"
                    || el.status == "Протестировано заказчиком без замечаний"
                    || el.status == "Протестировано заказчиком и возвращена на доработку";
            });

            const obj1 = {};

            targets.forEach((element) => {
                obj1[`${element.id}`] = element;
            });


            console.log(targets)


            const obj2 = {};

            resDataPost.forEach((element) => {
                obj2[`${element.id}`] = element;
            });


            const objResData = Object.assign({}, obj1, obj2);

            const massAll = Object.values(objResData);

            console.log("Все", massAll)

            const lifeDate = new Date()

            const cost = parseInt(localStorage.getItem("costHour").replace(/\s+/g, ''), 10)


            function getNumberOfDays(start, end) {
                const date1 = new Date(start);
                const date2 = new Date(end);
                // One day in milliseconds
                const oneDay = 1000 * 60 * 60 * 24;
                // Calculating the time difference between two dates
                const diffInTime = date2.getTime() - date1.getTime();
                // Calculating the no. of days between two dates
                const diffInDays = Math.round(diffInTime / oneDay);

                return diffInDays;
            }


            let countDate = 0;
            let allCost = 0;
            let fine = 0

            for (let i = 0; massAll.length > i; i++) {

                if (massAll[i].cost_of_work === "пусто") {
                    massAll[i].cost_of_work = "0"
                }


                if (massAll[i].status === "Выполнена" && massAll[i].closed_date > massAll[i].due_date) {

                    countDate += 1

                    if (massAll[i].priority === "Критический") {
                        Math.floor(fine += (cost * cunt(massAll[i].cost_of_work)) / 100 * 0.5 * getNumberOfDays(massAll[i].due_date, massAll[i].closed_date))
                    } else if (massAll[i].priority === "Высокий") {
                        Math.floor(fine += (cost * cunt(massAll[i].cost_of_work)) / 100 * 0.1 * getNumberOfDays(massAll[i].due_date, massAll[i].closed_date))
                    } else if (massAll[i].priority === "Низкий") {
                        Math.floor(fine += (cost * cunt(massAll[i].cost_of_work)) / 100 * 0.05 * getNumberOfDays(massAll[i].due_date, massAll[i].closed_date))
                    } else {
                        Math.floor(fine += (cost * cunt(massAll[i].cost_of_work)) / 100 * 0.05 * getNumberOfDays(massAll[i].due_date, massAll[i].closed_date))
                    }
                } else if (massAll[i].status === "Принята в работу" && lifeDate > new Date(massAll[i].due_date)) {
                    countDate += 1
                    if (massAll[i].priority === "Критический") {
                        Math.floor(fine += (cost * cunt(massAll[i].cost_of_work)) / 100 * 0.5 * getNumberOfDays(massAll[i].due_date, lifeDate))
                    } else if (massAll[i].priority === "Высокий") {
                        Math.floor(fine += (cost * cunt(massAll[i].cost_of_work)) / 100 * 0.1 * getNumberOfDays(massAll[i].due_date, lifeDate))
                    } else if (massAll[i].priority === "Низкий") {
                        Math.floor(fine += (cost * cunt(massAll[i].cost_of_work)) / 100 * 0.05 * getNumberOfDays(massAll[i].due_date, lifeDate))
                    } else {
                        Math.floor(fine += (cost * cunt(massAll[i].cost_of_work)) / 100 * 0.05 * getNumberOfDays(massAll[i].due_date, resData[i].closed_date))
                    }

                }
                Math.floor(allCost += Math.floor(parseInt(massAll[i].cost_of_work.replace(/\s+/g, ''), 10) / cost))

            }

            const commentListRender = massAll.map(resData =>
                <tr>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.numberTask}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.mailTypeWork}
                    </td>
                    <td style={{textAlign: "center", width: "15%"}} onClick={() => someCallback(resData)}>
                        {resData.formulation_task}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.priority}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.mailTypeRequest}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {ABBA(resData.date)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {ABBA(resData.due_date)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.status}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {ABBA(resData.closed_date)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {cunt(resData.cost_of_work)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.author}
                    </td>
                </tr>
            )


            // console.log(ABBA(lifeDate))
            return (
                <>

                    <h5 style={{textAlign: "center", marginTop: "20px"}}><b>Учет запросов (обращений)</b></h5>

                    <div style={{marginLeft: "40px"}}><b><p> г. Симферополь {ABBA(lifeDate)}</p></b></div>
                    <div style={{marginLeft: "40px"}}><b><p>За период с {taskDateStart} г. по {taskDateFinish} г.
                        Исполнителем оказаны Заказчику
                        следующие Услуги по технической поддержке и сопровождению КП 《1C предприятие 8》</p></b></div>
                    <div style={{marginLeft: "40px"}}><b><p>1. В АСУИ(3)зафиксированы следующие Обращения:</p></b></div>


                    <table className={"tableReport"}>

                        <tr>
                            <th>
                                Заявка №
                            </th>
                            <th>
                                Компонента системы
                            </th>
                            <th>
                                Содержание запроса
                            </th>
                            <th>
                                Приоритет
                            </th>
                            <th>
                                Тип обращения
                            </th>
                            <th>
                                Дата регистрации
                            </th>
                            <th>
                                План-дата выполнения
                            </th>
                            <th>
                                Текущий статус
                            </th>
                            <th>
                                Дата выполнения
                            </th>
                            <th>
                                Затрачено чел/час на решение
                            </th>
                            <th>
                                ФИО инициатора
                            </th>
                        </tr>
                        {commentListRender}

                    </table>
                    <div style={{marginLeft: "40px", textAlign: "center"}}><b><p>2. Всего зафиксировано
                        обращений: {massAll.length} шт, в т.ч.: {allCost} чел/час <br/>
                        Просроченных обращений зафиксировано {countDate} шт. <br/>
                        В соответствии с Техническим заданием штраф составил {Math.floor(fine)} Руб.</p></b></div>

                </>
            )

        } else return null
    }  
    const Louvre = () => {
        if (parm == "Louvre" && resData) {
            const massAll = resData

            let allCost = 0;
            let count = 0;
            const cost = parseInt(localStorage.getItem("costHour").replace(/\s+/g, ''), 10)
            for (let i = 0; resData.length > i; i++) {

                if (resData[i].cost_of_work !== "пусто") {
                    let k = 0
                    k = parseInt(resData[i].cost_of_work.replace(/\s+/g, ''), 10)
                    count += k
                    allCost += parseInt(resData[i].cost_of_work.replace(/\s+/g, ''), 10) / cost

                }
            }

            console.log(count)

            const commentListRender = massAll.map(resData =>
                <tr>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {ABBA(resData.date)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {ABBA(resData.get_started)}
                    </td>
                    <td style={{textAlign: "center", width: "15%"}} onClick={() => someCallback(resData)}>
                        {ABBA(resData.closed_date)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {cunt(resData.cost_of_work)}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {empty(resData.cost_of_work)}
                    </td>
                    <td style={{width: "30%"}} onClick={() => someCallback(resData)}>
                        {resData.formulation_task}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                        {resData.counterparty}
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                    </td>
                    <td style={{textAlign: "center"}} onClick={() => someCallback(resData)}>
                    </td>
                </tr>
            )

            console.log(massAll)
            return (
                <>
                    <div style={{marginLeft: "40px", textAlign: "center", marginTop: "20px"}}><b><p>Лист учета рабочего
                        времени</p></b></div>
                    <div style={{marginLeft: "40px", textAlign: "center"}}><b><p>к Договору № _____________________ от
                        «_____» ________ 20__ г.</p></b></div>
                    <div style={{display: "flex", width: "100%", marginBottom: "20px"}}>
                        <div style={{marginLeft: "5%"}}>
                            <div><b>Заказчик:</b></div>
                            <div><b>{customer}</b></div>
                        </div>


                        <div style={{marginRight: "5%", right: 0, position: "absolute"}}>
                            <div><b>Исполнитель:</b></div>
                            <div><b>ГК НЕКСОФТ</b></div>
                        </div>
                    </div>

                    <table className={"tableReport"}>


                        <tr>
                            <th rowSpan={2}>
                                Дата
                            </th>
                            <th rowSpan={2}>
                                Начало работы
                            </th>
                            <th rowSpan={2}>
                                Оконч. работы
                            </th>
                            <th rowSpan={2}>
                                Кол-во часов
                            </th>
                            <th rowSpan={2}>
                                Стоимость
                            </th>
                            <th rowSpan={2}>
                                Содержание работы
                            </th>
                            <th rowSpan={2}>
                                Заказчик
                            </th>
                            <th colSpan={2}>
                                Подписи
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Заказчика
                            </th>
                            <th>
                                Исполнителя
                            </th>
                        </tr>
                        {commentListRender}
                        <tr>
                            <th>
                                ✕
                            </th>
                            <th>
                                ✕
                            </th>
                            <th>
                                ✕
                            </th>
                            <th>
                                {allCost}
                            </th>
                            <th>
                                {count}
                            </th>
                            <th>
                                ✕
                            </th>
                            <th>
                                ✕
                            </th>
                            <th>
                                ✕
                            </th>
                            <th>
                                ✕
                            </th>
                        </tr>
                    </table>
                    <div style={{display: "flex", width: "100%", marginBottom: "20px"}}>
                        <div style={{marginLeft: "5%"}}>
                            <div><b>Представитель Заказчика</b></div>
                            <div><b>____________ / _______________/</b></div>
                        </div>


                        <div style={{marginRight: "5%", right: 0, position: "absolute"}}>
                            <div><b>Представитель Исполнителя</b></div>
                            <div><b>____________ / _______________/</b></div>
                        </div>
                    </div>
                </>)

        } else return null
    }

    return (<div>
        <Loader isLoading={loading}/>
        <div style={{marginBottom: "300px"}}>
            <General/>
            <TaskRep/>
            <PostRep/>
            <Louvre/>
        </div>
    </div>)
}
export default ReportResult;