import React, {useContext, useState} from "react";
import {Button, Form} from "react-bootstrap";
import AuthContext from "../../../context/AuthContext";

const SendToMailButton = () => {
    const {postDataDocs, taskCompletedOpen} = useContext(AuthContext);
    const {checkObj,send, setSend, resDataDocs} = useContext(AuthContext);
    const mail = localStorage.getItem("mail")

    const add = () => {
        let count = 0
        Object.values(checkObj).forEach(function (item) {
            if (item) {
                count++
            }
        })
        return count;
    };

    const addNew = (arr, typeDoc) => {
        const result = arr.map((el) => {
            el["TypeDoc"] = typeDoc;
            return el;
        });
        return result;
    };

    function handleClick(e) {
        let sendLocal = [
            ...addNew(resDataDocs.schet, "schet"),
            ...addNew(resDataDocs.Akty, "akt"),
            ...addNew(resDataDocs.RTU, "rtu"),
        ].filter((value) => checkObj[value.uiddoc])

        setSend(sendLocal)
        // postDataDocs(send);
        taskCompletedOpen()

    }

    return (
            <>
            <Button title={"Запросить на почту выбранные документы"} disabled={!add()} className={"buttomPost"} onClick={e => handleClick(e)}>Отправить на почту выбранное <svg width="26" height="26" fill="currentColor" viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm-.67 2L12 10.75 5.67 6h12.66ZM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 1.2 0L20 7.25V17a1 1 0 0 1-1 1Z"></path>
                </svg></Button>
        </>
    )
}
export default SendToMailButton