import React, {useContext} from "react";
import DateDrop from "../../TaskList/DateDrop/DateDrop";
import "../Style/doc.css"
import RTU from "../RTU/RTU";
import Akt from "../Akt/Akt";
import {ABBA} from "../../Helpers/Helpers";
import Check from "../Cek/Check";
import AuthContext from "../../../context/AuthContext";
import button from "bootstrap/js/src/button";

const SchetsList = (props) => {
    const {setSomePopupDataDocs} = useContext(AuthContext);
    const typedoc = "schet"
    const {openString} = useContext(AuthContext);
    const {resDataDocs,} = useContext(AuthContext);
    const {checkAll, setCheckAll} = useContext(AuthContext);

    const someCallback = (docId) => {
        setSomePopupDataDocs({
            ...docId,
            typedoc
        });
    };

    const docListRender = resDataDocs.schet.map(docId =>
        <>
            <div className={"bodyString"}>
                <details open={openString}>
                    <summary style={{display: "flex"}}>
                        <div style={{display: "flex", marginRight: "5px"}}>
                            <Check statusCheck={checkAll} typeSc={"schet"} doc={docId}/>
                        </div>
                        📂
                        <div style={{marginLeft: "5px"}} onClick={(e) => e.preventDefault() && someCallback(docId)}>
                            <div className={"shetsActiv"}
                                onClick={() => someCallback(docId)}>{docId.namedoc} {docId.nomerdoc} от {ABBA(docId.datedoc)} на
                                сумму: {docId.summadoc} руб. <br/>
                            </div>
                            <div style={{cursor: "auto"}}>Поставщик: {docId.postavschik}</div>
                            <div style={{marginBottom: "20px", cursor: "auto"}}>Покупатель: {docId.pokupatel}</div>
                        </div>
                    </summary>
                    <div className="folder">
                        <Akt id={docId.nomerdoc}/>
                        <RTU id={docId.nomerdoc}/>
                    </div>
                </details>

            </div>
        </>
    )

    return (
        <>
            <div className="container">
                {docListRender}
            </div>

        </>
    );

}
export default SchetsList