import React, {useCallback, useContext, useState} from "react";
import AuthContext from "../../../context/AuthContext";
import {Form, Modal} from "react-bootstrap";
import data from "bootstrap/js/src/dom/data";
import useAxios from "../../../utils/useAxios";
import "../Style/doc.css"
export const SomeDoc = () => {

    const {somePopupDataDocs, setSomePopupDataDocs} = useContext(AuthContext);
    const [dataSchet, setDataSchet] = useState(null)
    const {person} = useContext(AuthContext);
    const api = useAxios();


    const someHideCallback = useCallback(() => {
        setSomePopupDataDocs(null);
        setDataSchet(null)
    }, []);


    if (!somePopupDataDocs) {
        return null;
    }

    const {
        typedoc,
        uiddoc
    } = somePopupDataDocs;

    if (!dataSchet) {
        api.post("/v1/doc/", {
            "type": "3",
            "data": {
                "TypeDoc": typedoc,
                "UID": uiddoc,
            }
        }).then((response) => {
            const data = response.data
            setDataSchet(data)
        })
        return null
    } else {

        let buyer
        let supplier
        if (typedoc === "schet") {
            buyer = <p>Покупатель: {dataSchet.table[0].pokupatel}</p>
            supplier = <p>Поставщик: {dataSchet.table[0].postavschik}</p>
        }
        // console.log(buyer)
        //
        // console.log(typedoc)
        // }
        // }

// if (!dataSchet) {
//     fetchDataDocs()
// }
        const taskListRender = dataSchet.table.map(taskId =>
            <>
                <tbody>
                <tr>
                    <td>
                        {taskId.summa}
                    </td>
                    <td>
                        {taskId.stvakands}
                    </td>
                    <td>
                        {taskId.summasnds}
                    </td>
                    <td>
                        {taskId.cena}
                    </td>
                    <td>
                        {taskId.kolvo}
                    </td>
                    <td>
                        {taskId.tovar}
                    </td>
                </tr>
                </tbody>
            </>
        )


// props.taskId.get_started = ABBA(props.taskId.get_started);


        return (
            <Modal
                // style={{width: "320px"}}
                className={"ModalwidthFormCard"}
                size="xl"
                show
                onHide={someHideCallback}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton style={{backgroundColor: "#224cbd", color: "#ffffff"}}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h3 className={"headerTask"}>
                            Документ
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {buyer}
                    {supplier}
                    {/*<Form className={"formAll"}>*/}
                    <table className={"tbDoc"}>
                        <tr>
                            <th>Сумма</th>
                            <th>Ставка ндс</th>
                            <th>Сумма с ндс</th>
                            <th>Цена</th>
                            <th>Количество</th>
                            <th>Товар</th>
                        </tr>
                        {taskListRender}
                    </table>
                    {/*</Form>*/}
                </Modal.Body>
            </Modal>
        );
    }
}
export default SomeDoc;