import React, {useContext, useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import "react-widgets/styles.css";
import AuthContext from "../../../context/AuthContext";

const CekModal = (props) => {

    const {cekCompleted, setCekCompleted, postDataDocs} = useContext(AuthContext);
    const {send} = useContext(AuthContext);
    const mail = localStorage.getItem("mail")
    const [formMail, setFormMail] = useState(true)
    const [mailValue, setMailValue] = useState(mail)
    const [inputPassStart, setInputPassStart] = useState('')

    // console.log(send)


    const taskCompletedClose = () => {
        setCekCompleted(false);
        setFormMail(true)

        // postDataDocs(mailValue)
        // alert("dsf")
    }

    const taskCompletedReport = () => {
        setCekCompleted(false);
        postDataDocs(mailValue)
    }
    const taskCompletedRef = () => {
        setFormMail(!formMail)
    }

    const mailCompletedRef = () => {
        setMailValue(inputPassStart)
        setFormMail(!formMail)
    }

    if (formMail) {

        return (
            <>
                <Modal show={cekCompleted} onHide={taskCompletedClose} animation={true}>

                    <Modal.Header className={"TaskComp"} closeButton>
                    </Modal.Header>

                    <h3 style={{textAlign: "center"}}>Отправить документы на почту {mailValue}?</h3>

                    <Modal.Footer className={"TaskComp"}>
                        <Button variant="secondary" onClick={taskCompletedRef}>
                            Изменить почту
                        </Button>
                        <Button type={"submit"} variant="primary" onClick={taskCompletedReport}>
                            Подтвердить
                        </Button>
                    </Modal.Footer>


                </Modal>
            </>
        );
    } else {
        return (
            <Modal show={cekCompleted} onHide={taskCompletedClose} animation={true}>

            <Modal.Header className={"TaskComp"} closeButton>
            </Modal.Header>
        <h3 style={{textAlign: "center"}}>Укажите желаемый адрес электронной почты</h3>
        <Modal.Body className={"TaskComp"}>
            <Form.Group onChange={e => setInputPassStart(e.target.value)}
                        className="mb-3" controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Почта"/>
            </Form.Group>
        </Modal.Body>

        <Modal.Footer className={"TaskComp"}>
            <Button variant="secondary" onClick={taskCompletedRef}>
                Назад
            </Button>
            <Button type={"submit"} variant="primary" onClick={mailCompletedRef}>
                Подтвердить
            </Button>
        </Modal.Footer>
            </Modal>
    )
    }
}
export default CekModal