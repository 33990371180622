import Login from "../components/Intro/Login/Login";
import React from "react";
const LoginPage = () => {
  return (
      <>
        <Login/>
      </>
  );
};

export default LoginPage;
