import React, {useContext} from "react";
import {Button, Form} from "react-bootstrap";
import AuthContext from "../../../context/AuthContext";

const ShowAllButton = () => {
    const {openString, setOpenString} = useContext(AuthContext);

    function change() {
        setOpenString(!openString)
    }

    return (
        <>
            <Button className={"buttomString"} onClick={change}>
                Показать все 📋
            </Button>
        </>
    )
}
export default ShowAllButton