export function ABBA(s) {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        // weekday: 'long',
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    let inp = new Date(s)
    let out = inp.toLocaleString("ru", options)
    out = out.substring(0, out.indexOf(','));

    return out;
}