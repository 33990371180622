import React from "react";
import {Button, Modal} from "react-bootstrap";
import "../Style/Auth.css";
import {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import {useForm} from "react-hook-form";
import Background from "../Background/Background";


export const Login = () => {
    const {loginUser} = useContext(AuthContext);
    const {alertAuth, setAlertAuth} = useContext(AuthContext);

    const {
        register,
        formState: {
            errors
        },
        handleSubmit,
        reset,
    } = useForm({
        mode: "onBlur"
    });

    const onSubmit = (data) => {
        // const handleSubmit = e => {
        // data.preventDefault();
        if (data.username[0] === "+"){
            data.username = data.username.substring(1);
        }
        const username = data.username;
        const password = data.password;
        username.length > 0 && loginUser(username, password);


        // alert(JSON.stringify(data));
        // reset();
    }
    const handleClose = () => setAlertAuth(false);
    const handleShow = () => setAlertAuth(true);
    return (
        <div className={"context"}>
            <Modal show={alertAuth} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ошибка</Modal.Title>
                </Modal.Header>
                <Modal.Body><h1 style={{textAlign: "center", fontSize: "16pt"}}>Неверный пароль или логин &#128274;</h1>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*<Container className={"container-my "}>*/}
            <form className={"form"} onSubmit={handleSubmit(onSubmit)}>
                <div className={"formAuthDiv"}>
                    <h2 className={"h2"}>Войти:</h2>

                    <label style={{textAlign: "left", color: "#2a2929"}}>
                        Номер телефона:
                        <input className={"inputPhone"}
                               {...register('username', {
                                   required: '⛔️Поле обязательно к заполнению',
                                   minLength: {
                                       value: 11, message: "Некорректный номер"
                                   },

                                   maxLength: {
                                       value: 12, message: "Некорректный номер"
                                       // message: '⛔️Введите номер в формате 7XXXXXXXXXX'
                                   }
                               })}
                        />
                    </label>
                    <div>
                        {errors?.username && <p className={"p"}>{errors?.username?.message || "Error!"}</p>}
                    </div>

                    <label style={{textAlign: "left", color: "#2a2929"}}>
                        Пароль:
                        <input className={"inputPhone"} type={"password"}
                               {...register('password', {
                                   required: "⛔️Поле обязательно к заполнению",
                               })}
                        />
                    </label>
                    <div>
                        {errors?.password && <p className={"p"}>{errors?.password?.message || "Error!"}</p>}
                    </div>
                    {/*<div style={{justifyContent: "center"}}>*/}

                    <button className={"buttonFormAut"} type="submit">Войти</button>

                    <Button className={"buttonFormReg"} href={"/register"} variant="primary" type="submit">
                        Получить пароль
                    </Button>


                </div>
            </form>
            <Background/>
        </div>

    )

}

export default Login;