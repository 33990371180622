import React from "react";
import {Dropdown} from "react-bootstrap";
import UpPass from "../UpPassword/UpPass";
import {Link} from "react-router-dom";

const Settings = () => {


    return (
        <Dropdown>

            <Dropdown.Toggle style={{borderRadius: "5px", marginLeft: "10px"}} variant="primary" id="dropdown-basic">
                <svg width="26" height="26" fill="none" stroke="#ccffff" stroke-linecap="round" stroke-linejoin="round"
                     stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.296 9.015a3 3 0 1 0-.59 5.97 3 3 0 0 0 .59-5.97v0ZM19.518 12a7.238 7.238 0 0 1-.072.975l2.12 1.662a.507.507 0 0 1 .114.644l-2.005 3.469a.507.507 0 0 1-.615.215l-2.105-.847a.753.753 0 0 0-.711.082 7.703 7.703 0 0 1-1.01.588.747.747 0 0 0-.413.569l-.316 2.244a.519.519 0 0 1-.5.43h-4.01a.52.52 0 0 1-.501-.415l-.315-2.242a.753.753 0 0 0-.422-.573 7.278 7.278 0 0 1-1.006-.59.75.75 0 0 0-.708-.08l-2.105.848a.507.507 0 0 1-.616-.215L2.32 15.295a.506.506 0 0 1 .114-.644l1.792-1.406a.752.752 0 0 0 .28-.66 6.392 6.392 0 0 1 0-1.165.75.75 0 0 0-.284-.654L2.431 9.36a.507.507 0 0 1-.111-.641L4.325 5.25a.507.507 0 0 1 .616-.215l2.105.847a.755.755 0 0 0 .71-.082 7.71 7.71 0 0 1 1.01-.587.747.747 0 0 0 .414-.57L9.495 2.4a.52.52 0 0 1 .5-.43h4.01a.52.52 0 0 1 .502.416l.315 2.241a.753.753 0 0 0 .421.573c.351.17.687.366 1.006.59a.75.75 0 0 0 .709.08l2.104-.848a.507.507 0 0 1 .616.215l2.005 3.469a.506.506 0 0 1-.115.644l-1.791 1.406a.752.752 0 0 0-.284.66c.016.195.026.39.026.585Z"></path>
                </svg>
                Настройки

            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item>
                    <Link to="/" style={{textDecoration: "none"}}>
                        Изменить организацию
                    </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                    <UpPass/>
                </Dropdown.Item>

            </Dropdown.Menu>

        </Dropdown>
    )
}

export default Settings