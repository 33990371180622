import React, {useRef, useState} from "react";
import {Accordion, Button, Form, ListGroup} from "react-bootstrap";
import useAxios from "../../../utils/useAxios";
import "react-widgets/styles.css";
import {v4 as uuidv4} from 'uuid';
import "./ExampleDataForm.css"
import Loader from "../../loader/loader";


const NewComment = (props) => {

    const [r, setR] = useState(false);
    const [commentTextRender, setCommentTextRender] = useState(' ');
    const [localDateTodayRender, setLocalDateTodayRender] = useState(' ');
    const ref = useRef(null);
    const [loading, setLoading] = useState(false)

    const api = useAxios();
    const uuid = uuidv4();
    const taskId = props.id;
    const author = localStorage.getItem("author");
    let authorName = author.split("+").join(" ");
    const [commentText, setCommentText] = useState('Пустой комментарий');
    const options = {
        // era: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
    const date = new Date().toLocaleString("ru", options);
    let localDateToday = date.split(",").reverse().join(" ");
    localDateToday = localDateToday.slice(1);
    localDateToday = (localDateToday.split(' ').reverse().join(' '));


    const handleClose = () => {
        let data = [{
            "id": uuid,
            "refTask": taskId,
            "commentContent": commentText,
            "dateTime": localDateToday,
            "author": authorName
        }];

        setLoading(true)


        api.post('/v1/newComment/', data)
            .then(res => {
                setLoading(false)
                setCommentTextRender(commentText)
                setLocalDateTodayRender(localDateToday)
                setR(true)
            })
            .catch(err => {
                if (err.response) {
                    setLoading(false)

                    setCommentTextRender(commentText)
                    setLocalDateTodayRender(localDateToday)
                    setR(true)
                    // alert("Что-то пошло не так")
                } else {
                    setLoading(false);
                    setCommentTextRender(commentText)
                    setLocalDateTodayRender(localDateToday)
                    setR(true)
                }
            })




    }
    const NewRender = () => {

        if (r === true) {
            return (
                <Accordion flush className={"widthNewComment"}>
                    {/* "id": uuid,*/}
                    {/*"refTask": taskId,*/}
                    {/*"commentContent": commentText,*/}
                    {/*"dateTime": localDateToday,*/}
                    {/*"author": authorName*/}
                    <Accordion.Item>
                        <Accordion.Header>Новый комментарий</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup variant="flush">
                                <Form.Group className="mb-10" controlId="exampleForm.ControlTextarea1"
                                            style={{marginTop: "20px"}}>
                                    <Form.Control as="textarea" readOnly rows={7} value={commentTextRender}/>
                                </Form.Group>
                                <div>
                                    <div className={"pArg2"}>Автор:</div>
                                    {authorName}</div>
                                <div>
                                    <div className={"pArg2"}>Дата:</div>
                                    {localDateTodayRender}</div>

                                {/*<ListGroup.Item><Form.Control plaintext readOnly defaultValue={commentId.dateTime}/></ListGroup.Item>*/}

                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )
        } else {
            return (<></>)
        }
    }


    return (

        <>
            <style type="text/css">
                {`
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .widthNewComment{
        width:100%;
    }
    }
    `}
            </style>
            <NewRender/>

            <Accordion className={"widthNewComment"} defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Создать новый комментарий:</Accordion.Header>
                    <Accordion.Body>
                        <Form>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                                value={commentText} onChange={e => setCommentText(e.target.value)}>
                                <Form.Control as="textarea" rows={3}/>
                            </Form.Group>
                            <Button variant="primary" onClick={handleClose}>
                                Создать комментарий
                            </Button>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Loader isLoading={loading}/>

        </>
    );
}
export default NewComment